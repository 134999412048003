import { AnyAction } from 'redux';
import { LOGOUT_SUCCESS } from '../types/login';
import {
  APPROVE_REGISTRATION,
  APPROVE_REGISTRATION_SUCCESS,
  BANK_REGISTRATION_APPROVAL,
  BANK_REGISTRATION_APPROVAL_SUCCESS,
  BANK_REGISTRATION_DOCUMENT_REQUEST,
  BANK_REGISTRATION_DOCUMENT_REQUEST_SUCCESS,
  Registration,
  REJECT_REGISTRATION,
  REJECT_REGISTRATION_SUCCESS,
  SET_REGISTRATIONS,
  SET_REGISTRATIONS_SUCCESS,
} from '../types/request';

export type RegistrationsArray = Array<Registration>;
export type RegistrationsState = {
  docs: RegistrationsArray;
  page: number;
  limit: number;
  pages: number;
  total: number;
};
const initialState: RegistrationsState = {
  docs: [],
  page: 0,
  limit: 0,
  pages: 0,
  total: 0,
};
function registrationsReducer(
  state: RegistrationsState = initialState,
  action: AnyAction,
): RegistrationsState {
  switch (action.type) {
    case SET_REGISTRATIONS:
    case SET_REGISTRATIONS_SUCCESS:
      return action.body;

    case APPROVE_REGISTRATION:
    case APPROVE_REGISTRATION_SUCCESS: {
      const { status } = action.body;
      const { registrationId } = action.parameters;
      const modifiedState = state.docs.map((registration) => {
        if (registration.id === registrationId) {
          return { ...registration, status: status };
        } else {
          return registration;
        }
      });
      return { ...state, docs: modifiedState };
    }

    case REJECT_REGISTRATION:
    case REJECT_REGISTRATION_SUCCESS: {
      const { status } = action.body;
      const { registrationId } = action.parameters;
      const modifiedState = state.docs.map((registration) => {
        if (registration.id === registrationId) {
          return { ...registration, status: status };
        } else {
          return registration;
        }
      });
      return { ...state, docs: modifiedState };
    }

    case BANK_REGISTRATION_APPROVAL:
    case BANK_REGISTRATION_APPROVAL_SUCCESS:{
      const {bankRegistration = {}, status = ''} = action.body;
      const { registrationId, bankRegistrationId } = action.parameters;
      const modifiedState = state.docs.map((registration) => {
        if (registration.id === registrationId) {
          return {...registration, bankRegistration: { ...registration?.bankRegistration, ...bankRegistration }, status: status};
        } else {
          return registration;
        }
      });
      return { ...state, docs: modifiedState };
    }


    case BANK_REGISTRATION_DOCUMENT_REQUEST:
    case BANK_REGISTRATION_DOCUMENT_REQUEST_SUCCESS:{
      const {bankRegistration = {}} = action.body;
      const { registrationId, bankRegistrationId } = action.parameters;
      const modifiedState = state.docs.map((registration) => {
        if (registration.id === registrationId) {
          return {...registration, bankRegistration: { ...registration?.bankRegistration, ...bankRegistration }};
        } else {
          return registration;
        }
      });
      return { ...state, docs: modifiedState };
    }


    case LOGOUT_SUCCESS: {
      return initialState;
    }

    default:
      return state;
  }
}

export default registrationsReducer;
