import CashAccount from './CashAccount';
import LenderPendingStatements from './lenderPendingStatements';
import {RouterOutLet} from "./DashboardFolder";
import { useRouteMatch } from 'react-router';


export default function CashIndex() {
const { path } = useRouteMatch();
  const Routes = [
    { path: `${path}/statement`, component: CashAccount,  },
    { path: `${path}/pending-statements`, component: LenderPendingStatements, exact:true },
  ];
  return <RouterOutLet Routes={Routes} exactPath={{ path, to: `${path}/statement` }} />;
}
