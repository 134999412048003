import {
  Typography,
  Button,
  TextField,
  useMediaQuery,
  FormHelperText,
  Link,
} from '@material-ui/core';
import { useState, useEffect, useRef } from 'react';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router';
import { onEntityLogin, onEntityOtpVerification } from '../../redux-store/actions/login';
import { VerifyOtpPayload } from '../../redux-store/types/login';
import { COLORS } from '../../constants';
import { useSnackbar } from 'notistack';
import { Countdown } from '../../utils/CountdownTimer';


const accountTxt: any = {
  fontSize: 16,
  color: '#333333',
  textAlign: 'center',
  marginTop: 30,
  fontFamily: 'PlusJakartaSans-Regular',
};
const spanTxt: any = {
  color: COLORS.juma_theme_clr,
  fontSize: 16,
  fontWeight: 600,
  marginLeft: 6,
};
const emailImg: any = {
  width: 80,
  display: 'block',
  margin: 'auto',
};
const emailTxt: any = {
  fontSize: 14,
  fontFamily: 'PlusJakartaSans-Regular',
  color: '#282828',
  width: '80%',
  textAlign: 'center',
  margin: '20px auto 40px',
};
const otpTextFeild: any = {
  width: 45,
  marginRight: 5,
  marginLeft: 5,
};

const validationSchema = yup.object({
  otp: yup.array().of(yup.number()).test('OTP', function(value, context){
    const {createError, parent, path} = context
    const nonEmptyField = parent?.otp?.reduce((acc: number, cur: string) => {
        if(cur !== undefined) {
          acc += 1
        }
        return acc
    }, 0)
    if(nonEmptyField < 6){
      return createError({
        message: 'OTP is required',
        path,
      }); 
    }
    return true;
  })
});

const INTERVAL = 60 * 1000;
export default function OtpValidation() {
  const isXsMobile = useMediaQuery('(max-width : 360px)');
  const isMobile = useMediaQuery('(max-width : 480px)');
  const isTablet = useMediaQuery('(max-width : 768px)');
  const isBigTablet = useMediaQuery('(max-width : 1180px)');
  const history = useHistory();
  const signUpContainer: any = {
    backgroundColor: isMobile || isXsMobile ? '#FFFFFF': '#F4F4F4',
    width: '100%',
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    height: '100vh',
    overflow: 'auto',
  };

  const formContainer: any = {
    backgroundColor: COLORS.default_white,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    boxSizing: 'border-box',
    width: isMobile || isXsMobile ? '94%' : isTablet || isBigTablet ? '60%' : '42%',
    maxWidth: 520,
    margin: ' 30px auto',
    padding: isMobile || isXsMobile ? '20px 12px' : isTablet || isBigTablet ? 20 : 40,
  };
  const signUpMerchant: any = {
    marginTop: isMobile || isXsMobile || isTablet || isBigTablet ? 80 : 120,
    fontSize: isMobile || isXsMobile || isTablet || isBigTablet ? 24 : 32,
    fontWeight: 600,
    fontStyle: 'PlusJakartaSans-Bold',
  };
  const emailInputWrapper: any = {
    margin: isMobile || isXsMobile || isTablet || isBigTablet ? 10 : '10px 30px',
  };
  const btnContinue: any = {
    width: isMobile || isXsMobile ? '100%' : isTablet || isBigTablet ? '90%' : '80%',
    background: '#1A7CFA',
    boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.0605059)',
    borderRadius: 2,
    color: COLORS.default_white,
    margin: '30px auto',
    display: 'block',
  };

  const [timerExpired, setTimerExpired] = useState(false);
  const [targetTime, setTargetTime] = useState<number>(Date.now() + INTERVAL);
  const dispatch = useDispatch();
  const { state } = useLocation<{
    otpToken: string;
    role: string;
    email: string;
  }>();
  const [otpToken, setOtpToken] = useState(state?.otpToken);
  const [role] = useState(state?.role);
  const [email] = useState(state?.email);
  const { enqueueSnackbar } = useSnackbar();
  type OtpFormikState = {
    otp: string[];
  };

  const initialValues: OtpFormikState = {
    otp: ['', '', '', '', '', ''],
  };

  const formik: any = useFormik({
    initialValues,
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      const otpString = values?.otp?.join('');
      const otpPayload: VerifyOtpPayload = { otpToken, role, otp: otpString };
      try {
        if (targetTime !== 0) {
          const response = await dispatch(
            onEntityOtpVerification(otpPayload),
          )
          const {user: {role}} = response as any
          if(role === 'admin') history.push('/user-management')
          else history.push('/active-loan')
        }
      } catch (error) {
        enqueueSnackbar((error as Error).message, {
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'center',
          },
          variant: 'error',
        });
      }
    },
  });

  const resendOTP = async () => {
    const response = (await dispatch(onEntityLogin({ email }))) as unknown as any;
    if (response?.otpToken) {
      setOtpToken(response?.otpToken);
      setTargetTime(Date.now() + INTERVAL);
      setTimerExpired(false);
    }
  };

  const otpRef = useRef<any>([]);
  useEffect(() => {
    otpRef.current[0].focus();
  }, []);

  const onChangeHandler = (value: string, index: number) => {
    if (!/^[\d]*$/.test(value)) {
      return;
    }
    if ((value && !formik.values.otp[index].length) || !value) {
      formik.setFieldValue(`otp[${index}]`, value);
    }

    if (index < 5 && value) {
      otpRef.current[index + 1].focus();
    }
  };

  const handleKeyPress = (keyCode: number, index: number) => {
    if (keyCode === 8) {
      if (!formik.values.otp[index] && index >= 0) {
        otpRef.current[index - 1].focus();
      }
    }
  };

  const hasError=
    formik?.touched?.otp &&
    Boolean(formik?.errors?.otp);
  
  const errorText=
    formik?.touched?.otp &&
    formik?.errors?.otp;


  return (
    <div>
      <div style={signUpContainer} className="createMerchant">
        <Typography style={signUpMerchant}>Verify Your Email Account</Typography>
        <form onSubmit={formik.handleSubmit} style={formContainer}>
          <div style={emailInputWrapper}>
            <img src="/icons/otp_email.svg" style={emailImg} alt="verify-email" />
            <Typography style={emailTxt}>
              6-digit OTP has been sent to your registered email id.
            </Typography>
            <div className="otpSec">
            <p
              style={{
                fontSize: 14,
                color: '#282828',
                opacity: 0.5,
                marginBottom:
                  isMobile || isXsMobile ? 0 : isTablet || isBigTablet ? '0px' : '-12px',
              }}
            >
              Enter OTP
            </p>
            <div>
              {formik?.values?.otp?.map((digit: string, i: number) => {
                return (
                  <TextField
                    key={i}
                    size="small"
                    margin="normal"
                    style={otpTextFeild}
                    id={`otp[${i}]`}
                    name={`otp[${i}]`}
                    className="otpTextField"
                    autoComplete="off"
                    inputRef={(ref) => (otpRef.current[i] = ref)}
                    onKeyDown={({ keyCode }) => handleKeyPress(keyCode, i)}
                    inputProps={{ maxLength: 1, inputMode: 'numeric' }}
                    value={digit}
                    onChange={({ target: { value } }) => onChangeHandler(value, i)}
                  />
                );
              })}
            </div>
            {hasError && <FormHelperText style={{color: 'red'}}>{errorText}</FormHelperText>}
            </div>
            {!timerExpired ? (
              <Typography style={accountTxt}>
                You can resend OTP in{' '}
                  <Countdown targetTime={targetTime} setTimerExpired={setTimerExpired} />
              </Typography>
            ) : (
              <Typography style={accountTxt}>

                <Link href='#' onClick={resendOTP} className="textUnderline" style={spanTxt}>
                  Resend OTP
                </Link>

              </Typography>
            )}
            <Button size="large" variant="contained" style={btnContinue} type="submit">
              Continue
            </Button>
          </div>
        </form>
      </div>
    </div>
  );
}
