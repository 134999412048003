import React from 'react';
import { Redirect, Route, Router, Switch } from 'react-router-dom';
import Home from '../components/dashboard';
import Login from '../components/auth';
import store, { history } from '../store';
import ProtectedRoute from './ProtectedRoute';
import RedirectRoute from './RedirectRoute';
import UserManagement from '../components/userManagement/userManagement';
import UserEntity from '../components/userManagement/users';
import MerchantList from '../components/merchants/MerchantList';
import MerchantDetail from '../components/merchants/MerchantDetail';
import Setting from '../components/settings/Setting';
import OtpValidation from '../components/auth/OtpValidation';
import TermsOfUse from '../components/dashboard/termsOfUse';
import PrivacyPolicy from '../components/dashboard/privacyPolicy';
import { Backdrop, CircularProgress, makeStyles, Snackbar } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { useSelector } from 'react-redux';
import { number, string } from 'yup';
import DirectID from '../components/merchants/DirectID';
import DocumentList from '../components/merchants/DocumentList';
import ActiveLoan from '../components/dashboard/ActiveLoan';
import MaturedLoan from '../components/dashboard/MaturedLoan';
import CashAccount from '../components/dashboard/CashAccount';
import LenderPendingStatements from '../components/dashboard/lenderPendingStatements';
import CashIndex from '../components/dashboard/cashIndex';
export interface AuthType {
  token: string | undefined;
  component: React.ComponentType<any>;
}

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
}));

export function SimpleBackdrop({ open }: { open: number }) {
  const classes = useStyles();
  return (
    <div>
      <Backdrop className={classes.backdrop} open={!!open}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </div>
  );
}

interface LoaderType {
  showLoader: number;
}

function AppRoutes() {
  const loader = useSelector((state: { loader: LoaderType }) => state.loader);
  return (
    <Router history={history}>
      <SimpleBackdrop open={loader?.showLoader} />
      <Switch>
        <Route
          exact
          path="/"
          render={(props) => (
            <Redirect {...props} to="/user-management" from={props.location.pathname} />
          )}
        ></Route>
        <ProtectedRoute path="/active-loan" component={ActiveLoan} />
        <ProtectedRoute path="/matured-loan" component={MaturedLoan} />
        <ProtectedRoute path="/cash-account" component={CashIndex} />
        <ProtectedRoute path="/cash-account/pending-statements" component={LenderPendingStatements} />
        <ProtectedRoute path="/settings" component={Setting} />
        <RedirectRoute path="/login" component={Login} />
        <RedirectRoute path="/verify-otp" component={OtpValidation} />
        <ProtectedRoute exact path="/user-management" component={UserManagement} />
        <ProtectedRoute path="/user-management/:entityId/users" component={UserEntity} />
        <ProtectedRoute exact path="/merchants" component={MerchantList} />
        <ProtectedRoute path="/merchants/:registrationId/enigma-json" component={MerchantDetail} />
        <ProtectedRoute path="/merchants/:registrationId/directid-json" component={DirectID} />
        <ProtectedRoute path="/merchants/:registrationId/documents" component={DocumentList} />
        <ProtectedRoute path="/terms-of-use" component={TermsOfUse} />
        <ProtectedRoute path="/privacy-policy" component={PrivacyPolicy} />
      </Switch>
    </Router>
  );
}

export default AppRoutes;
