import { 
  Paper, 
  Box,
} from '@material-ui/core';
import { useSelector } from 'react-redux';
import { RootStateType } from '../../redux-store/reducers';
import { SIDENAV_ITEMS } from '../../utils/constants';
import SideBar from '../forms/SideBar';
import LenderBar from './lenderBar';
import Header from '../forms/headerBar';

export default function TermsOfUse(props: any) {
  const { entity } = useSelector((store: RootStateType) => store.authReducer);
  return (
    <div className="flex_column">
      <Header />
      <div className="flex_row minHeight bgThemeColor">
        <SideBar history={props.history} list={SIDENAV_ITEMS} />
        <div>
        <LenderBar entity={entity} />
          <div className="container">
            <div className="headingText margin_top_10">TERMS OF USE</div>
            <Paper square elevation={0}>
                <Box className='textWrapper'>
                  <Box className='termsOfUseText'>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, 
                    sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. 
                    Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris 
                    nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in 
                    reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. 
                    Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.<br/><br/>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, 
                    sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. 
                    Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris 
                    nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in 
                    reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. 
                    Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.<br/><br/>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, 
                    sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. 
                    Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris 
                    nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in 
                    reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. 
                    Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.<br/><br/>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, 
                    sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. 
                    Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris 
                    nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in 
                    reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. 
                    Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.<br/>
                  </Box>
                </Box>
            </Paper>
          </div>
        </div>
      </div>  
    </div>
  );
}
