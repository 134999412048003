import { SIDENAV_ITEMS } from '../../utils/constants';
import SideBar from '../forms/SideBar';
import {
  Typography,
  Button,
  Paper,
  Box,
  Table,
  TableCell,
  TableHead,
  TableRow,
  TableContainer,
  TableBody,
} from '@material-ui/core';
import { useEffect, useState } from 'react';
import Switch from '@material-ui/core/Switch';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { useRouteMatch, useParams, useHistory } from 'react-router-dom';
import { onLogout } from '../../redux-store/actions/login';
import { useDispatch, useSelector } from 'react-redux';
import { getEntityUsers, updateEntityUserStatus } from '../../redux-store/actions/entity';
import { User } from '../../redux-store/types/entity';
import CreateEntityUser from './CreateEntityUser';
import Header from '../forms/headerBar';

function UserEntity(props: any) {
  const { url } = useRouteMatch();
  const history = useHistory();
  const [open, setOpen] = useState<boolean>(false);
  const [entityUsers, setEntityUsers] = useState<User[]>([]);
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const dispatch = useDispatch();
  const { entityId } = useParams<{ entityId: string }>();
  //   const entityList = useSelector((state: { entites: Entites }) => state.entites);
  const loader = useSelector((state : {loader : {showLoader : number}}) => state.loader)

  const addEntityUserToList = (user: User) => {
    setEntityUsers((entityUsers) => [...entityUsers, user]);
  };

  const changeEntityUserStatus = async (
    entityId: string,
    entityUserId: string,
    status: boolean,
  ) => {
    // console.log(entityId, entityUserId, status);
    const changedStatus = await dispatch(
      updateEntityUserStatus(entityId, entityUserId, status),
    );
    setEntityUsers((entityUsers) => {
      return entityUsers.map((entityUser) => {
        return entityUser.id === entityUserId
          ? { ...entityUser, ...changedStatus }
          : entityUser;
      });
    });
  };

  useEffect(() => {
    async function fetch() {
      const entityUsers = await dispatch(getEntityUsers(entityId));
      setEntityUsers(entityUsers as unknown as User[]);
    }
    fetch();
  }, []);

  return (
    <div className="flex_column">
      <Header />
      <div className="flex_row home bgThemeColor">
        <SideBar history={props.history} list={SIDENAV_ITEMS} />
        <div className="container">
          <div className="usersHeader">
            <div className="firstSec">
              <img
                src="/icons/back_icon.svg"
                alt="Go back"
                className="backImg"
                onClick={() => history.goBack()}
              />
              <Typography>USERS</Typography>
            </div>
            <Button variant="contained" color="primary" onClick={handleClickOpen}>
              Add User
            </Button>
          </div>

          <Paper square className="umTableSec" elevation={0}>
            <TableContainer className="tableWidth">
              <Table aria-label="simple table">
                <TableHead>
                  <TableRow className="textBold">
                    <TableCell>S. No.</TableCell>
                    <TableCell>Employee Name</TableCell>
                    <TableCell>Email Address</TableCell>
                    <TableCell>Activate/Deactivate</TableCell>
                    <TableCell></TableCell>
                    <TableCell></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {entityUsers.map((user, index) => {
                    return (
                      <TableRow className="textNormal">
                        <TableCell>{index + 1}</TableCell>
                        <TableCell className="textTransform">
                          {user?.user?.firstName}
                        </TableCell>
                        <TableCell>{user?.user?.email}</TableCell>
                        <TableCell>
                          <Box>
                            <FormControlLabel
                              className={
                                user?.isActive ? 'switchEnable' : 'switchDisable'
                              }
                              control={
                                <Switch
                                  size="small"
                                  color="primary"
                                  checked={user?.isActive}
                                  onChange={(e) =>
                                    changeEntityUserStatus(
                                      user?.entityId,
                                      user?.id,
                                      e.target.checked,
                                    )
                                  }
                                />
                              }
                              labelPlacement="start"
                              label=""
                            />
                          </Box>
                        </TableCell>
                        <TableCell align="right">
                          {/* <Button
                                            variant="outlined"
                                            color="primary"
                                            onClick={() => history.push(`/users`)}
                                            >View Users</Button> */}
                        </TableCell>
                        <TableCell></TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
              {!loader.showLoader && (entityUsers?.length === 0) && <div
                    className='noUsersText'
                >
                    No Users
              </div>}
            </TableContainer>
          </Paper>

          <CreateEntityUser
            open={open}
            handleClose={handleClose}
            entityId={entityId}
            addEntityUserToList={addEntityUserToList}
          />
        </div>
      </div>
    </div>
  );
}

export default UserEntity;
