import { Box, Paper, Typography } from '@material-ui/core';
import { useLocation } from 'react-router';
import { SIDENAV_ITEMS } from '../../utils/constants';
import { useHistory } from 'react-router';
import SideBar from '../forms/SideBar';
import Header from '../forms/headerBar';

export default function DirectID(props: any) {
  const history = useHistory();
  const location = useLocation<any>();
  const accountsString = location.state?.accounts;
  const accounts = accountsString? JSON.parse(accountsString) : `Empty`;

  return (
    <Box className="flex_column">
      <Header />
      <Box className="flex_row home bgThemeColor">
        <SideBar history={props.history} list={SIDENAV_ITEMS} />
        <Box className="container">
          <Box className="usersHeader">
            <Box className="firstSec">
              <img
                src="/icons/back_icon.svg"
                alt="Go back"
                className="backImg"
                onClick={() => history.goBack()}
              />
              <Typography style={{ textTransform: 'none' }}>Direct ID</Typography>
            </Box>
          </Box>
          <Paper square elevation={0} className="enigmaCard">
            <Box className="enigmaSec">
              <pre>{JSON.stringify(accounts, null, 4)}</pre>
            </Box>
          </Paper>
        </Box>
      </Box>
    </Box>
  );
}
