export const SET_REGISTRATIONS = 'SET_REGISTRATIONS';
export const SET_REGISTRATIONS_SUCCESS = 'SET_REGISTRATIONS_SUCCESS';

export const APPROVE_REGISTRATION = 'SET_REGISTRATIONS';
export const REJECT_REGISTRATION = 'SET_REGISTRATIONS';

export const APPROVE_REGISTRATION_SUCCESS = 'APPROVE_REGISTRATIONS_SUCCESS';
export const REJECT_REGISTRATION_SUCCESS = 'REJECT_REGISTRATIONS_SUCCESS';

export const GET_REGISTRATION = 'GET_REGISTRATION';
export const GET_REGISTRATION_SUCCESS = 'GET_REGISTRATION_SUCCESS';

export const GET_BUSSINESS_CATEGORY = 'GET_BUSSINESS_CATEGORY';
export const GET_BUSSINESS_CATEGORY_SUCCESS = 'GET_BUSSINESS_CATEGORY_SUCCESS';

export const BANK_REGISTRATION_APPROVAL = 'BANK_REGISTRATION_APPROVAL'
export const BANK_REGISTRATION_APPROVAL_SUCCESS = 'BANK_REGISTRATION_APPROVAL_SUCCESS'


export const BANK_REGISTRATION_DOCUMENT_REQUEST = 'BANK_REGISTRATION_DOCUMENT_REQUEST'
export const BANK_REGISTRATION_DOCUMENT_REQUEST_SUCCESS = 'BANK_REGISTRATION_DOCUMENT_REQUEST_SUCCESS'


export const SEND_EMAIL_SUCCESS = 'SEND_EMAIL_SUCCESS'
export const SEND_EMAIL = 'SEND_EMAIL'

interface RegistrationType {
  id: string;
  name: string;
  email: string;
  ownerTitle: string;
  ownerFirstName: string;
  ownerLastName: string;
  address: string;
  category: number;
  ein: string;
  enigmaScore: string;
  updatedAt: string;
  createdAt: string;
  approvedBy: string;
  rejectedBy: string;
  status: string;
  'categoryDetails.name': string;
  'categoryDetails.id': number;
  enigmaResponse: any;
  city: string;
  suit: string;
  state: string;
  zipCode: string;
  country: string;
  bankRegistration: any;
}

interface RegistrationParamsType {
  page: number;
  limit: number;
  order: string;
  sort: string;
  name: string;
}

export type Registration = RegistrationType;
export type RegistrationParams = RegistrationParamsType;

export interface CategoryBody {
  activeLogo: string;
  id: number;
  inActiveLogo: string;
  name: string;
}

export interface Category {
  body: CategoryBody[];
  error: any;
  parameters: any;
}

export const SET_PARAMETERS = 'SET_PARAMETERS';
