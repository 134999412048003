import { styled, Switch } from '@material-ui/core';

export const Android12Switch = styled(Switch)(({ theme }) => ({
  padding: 8,
  marginRight: 14,
  '& .MuiSwitch-track': {
    borderRadius: 22 / 2,
    backgroundColor: '#93c5fd',
    '&:before, &:after': {
      content: '""',
      position: 'absolute',
      top: '50%',
      transform: 'translateY(-50%)',
      width: 16,
      height: 16,
    },
  },
  '& .MuiSwitch-thumb': {
    boxShadow: 'none',
    width: 16,
    height: 16,
    margin: 2,
    color: '#3b82f6',
    '&:before': {
      content: "''",
      position: 'absolute',
      width: '100%',
      height: '100%',
      left: 0,
      top: 0,
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center',
      backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="6" height="10" viewBox="0 0 6 10"><path fill="${encodeURIComponent(
        '#fff',
      )}" d="M5.28033 1.21772C5.57322 1.51061 5.57322 1.98548 5.28033 2.27838L2.31066 5.24805L5.28033 8.21772C5.57322 8.51061 5.57322 8.98548 5.28033 9.27838C4.98744 9.57127 4.51256 9.57127 4.21967 9.27838L0.71967 5.77838C0.426776 5.48548 0.426776 5.01061 0.71967 4.71772L4.21967 1.21772C4.51256 0.924824 4.98744 0.924824 5.28033 1.21772Z"/></svg>')`,
    },
  },

  '& .MuiSwitch-colorSecondary.Mui-checked + .MuiSwitch-track': {
    backgroundColor: '#93c5fd',
  },
  '& .MuiSwitch-switchBase': {
    '&.Mui-checked': {
      color: '#fff',

      '& .MuiSwitch-thumb:before': {
        backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="6" height="10" viewBox="0 0 6 10"><path fill="${encodeURIComponent(
          '#fff',
        )}" d="M0.71967 1.21772C0.426777 1.51061 0.426777 1.98548 0.71967 2.27838L3.68934 5.24805L0.71967 8.21772C0.426777 8.51061 0.426777 8.98548 0.71967 9.27838C1.01256 9.57127 1.48744 9.57127 1.78033 9.27838L5.28033 5.77838C5.57322 5.48548 5.57322 5.01061 5.28033 4.71772L1.78033 1.21772C1.48744 0.924824 1.01256 0.924824 0.71967 1.21772Z"/></svg>')`,
      },
    },
  },
}));

export const COLORS = {
  BUTTON_GREEN_COLOR: '#09f4c7',
  default_white: '#fff',
  juma_theme_clr: '#1A7CFA',
  default_black: '#000',
  table_head: '#F4F9FF',
  theme_light_blue: '#F4F9FF',
};
export const textFieldStyle = {
  fontFamily: 'PlusJakartaSans-Regular',
  fontSize: 14,
  color: '#282828',
};
export const btnContinue = {
  width: '100%',
  background: '#1A7CFA',
  boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.0605059)',
  borderRadius: 2,
  color: COLORS.default_white,
  marginTop: 40,
};
export const passwordSec = {
  display: 'flex',
  alignItems: 'center',
  marginTop: 40,
  marginBottom: 10,
};
export const forgotPswd = {
  textDecoration: 'none',
};
