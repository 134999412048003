import { connect, useSelector } from 'react-redux';
import { Redirect, Route, RouteProps } from 'react-router';
import { AuthType } from '.';
import { RootStateType } from '../redux-store/reducers';

function RedirectRoute({ token, component: Component, ...rest }: AuthType & RouteProps) {
  const { user } = useSelector((store: RootStateType) => store.authReducer);
  return (
    <Route
      {...rest}
      render={(props) =>
        !!token ? (
          user.role == 'admin' ?
            <Redirect to="/user-management" from={props.location.pathname} /> :
            <Redirect to="/active-loan" from={props.location.pathname} />
        ) : (
          <Component {...props} />
        )
      }
    />
  );
}

export default connect(({ authReducer }: RootStateType) => {
  return { token: authReducer.token };
}, {})(RedirectRoute);
