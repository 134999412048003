import React from 'react';
import { Box } from '@material-ui/core';
import { EntityInLoginResponse } from '.';

export default function LenderBar({ entity }: { entity: EntityInLoginResponse }) {
  return (
    <>
      <Box className="lenderBannerSec">
        <Box className="lenderWrapper">
          <p>
            Client : <span>{entity?.name}</span>
          </p>
        </Box>
        <Box className="lenderWrapper">
          <p>
            Committed Capital:{' '}
            <span className="mobAlign">{`$${parseInt(
              entity?.commitmentAmount,
            ).toLocaleString('en-US')}`}</span>
          </p>
        </Box>
      </Box>
    </>
  );
}
