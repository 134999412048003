import { Box, Button, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@material-ui/core';
import { useLocation } from 'react-router';
import { SIDENAV_ITEMS } from '../../utils/constants';
import { useHistory } from 'react-router';
import SideBar from '../forms/SideBar';
import Header from '../forms/headerBar';
import { Link } from 'react-router-dom';
import { useState } from 'react';
import DocumentPreview from './DocumentPreview';

const TableHeader = [
    'S. No',
    'Document Name',
    'Preview'
]

export default function DocumentList(props: any) {
  const history = useHistory();
  const {state : {bankDocuments = []}} = useLocation<{bankDocuments: string[]}>();
  console.log(bankDocuments)

  const [viewFile, setViewFile] = useState('')
  const [mimeType, setMimeType] = useState('')

  return (
    <Box className="flex_column">
      <Header />
      <Box className="flex_row minHeight bgThemeColor">
        <SideBar history={props.history} list={SIDENAV_ITEMS} />
        <Box className="container">
          <Box className="usersHeader">
            <Box className="firstSec">
              <img
                src="/icons/back_icon.svg"
                alt="Go back"
                className="backImg"
                onClick={() => history.goBack()}
              />
              <Typography style={{ textTransform: 'none' }}>Documents</Typography>
            </Box>
          </Box>
          <Paper square elevation={0} className="enigmaCard">
            <Box>
              <TableContainer className="tableWidth">
                <Table>
                    <TableHead>
                        <TableRow className="textBold">
                            {TableHeader.map((header, index) => (
                                <TableCell key={header}>
                                    {header}
                                </TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>{bankDocuments?.map((row, i) => _getTableBody(row, i, setViewFile, setMimeType))}</TableBody>
                </Table>
                {(bankDocuments?.length === 0) && <div
                    className='noDocText'
                >
                    No Bank Documents
                </div>}
              </TableContainer>
            </Box>
          </Paper>
        </Box>
      </Box>
      <DocumentPreview viewFile={viewFile} setViewFile={setViewFile} mimeType={mimeType} />
    </Box>
  );
}



function _getTableBody(row: any, index: number, setViewFile: (viewFile: string) => void, setMimeType: (mimeType: string) => void) {
    const {
      id = '', name = '', url = '', mimeType = ''
    } = row;
    const modifiedUrl = url.replace("download", "thumbnail");
    return (
      <TableRow key={id} className="documentTableBodyText">
        <TableCell component="th" scope="row">
          {index + 1}
        </TableCell>
        <TableCell>{name}</TableCell>
        <TableCell className='previewLink'>
            {/* <Link to={{pathname: url}} target='_blank' onClick={() => setViewFile(true)}>Preview</Link> */}
            <Button onClick={() => {
              setViewFile(modifiedUrl)
              setMimeType(mimeType)
              // window.open(modifiedUrl, '_blank')
            }
            }>Preview</Button>
        </TableCell>
      </TableRow>
    );
  }