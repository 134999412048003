import { AnyAction } from 'redux';
import {
  CREATE_ENTITY,
  CREATE_ENTITY_SUCCESS,
  Entity,
  GET_ENTITIES,
  GET_ENTITIES_SUCCESS,
  UPDATE_ENTITY_STATUS,
  UPDATE_ENTITY_STATUS_SUCCESS,
} from '../types/entity';

export type Entites = Array<Entity>;
const initialState: Entites = [];
function entityReducer(state: Entites = initialState, action: AnyAction): Entites {
  switch (action.type) {
    case GET_ENTITIES:
    case GET_ENTITIES_SUCCESS:
      return action.body;

    // case CREATE_ENTITY:
    case CREATE_ENTITY_SUCCESS:
      return [...state, action.body];

    case UPDATE_ENTITY_STATUS:
    case UPDATE_ENTITY_STATUS_SUCCESS:
      const { entityId } = action.parameters;
      return state.map((entity) => (entity.id === entityId ? action.body : entity));

    default:
      return state;
  }
}

export default entityReducer;
