import { connect } from 'react-redux';
import '../../App.scss';
import { Button } from '@material-ui/core';
import { onEntityLogin, onLogin } from '../../redux-store/actions/login';
import { EntityLoginPayload } from '../../redux-store/types/login';
import { TextInput } from '../forms/textInput';
import { useFormik } from 'formik';
import * as yup from 'yup';

import {
  btnContinue,
} from './loginUtils';
import { useSnackbar } from 'notistack';


type LoginFormikState = {
  email: string;
};

const initialValues: LoginFormikState = {
  email: '',
};

const validationSchema = yup.object().shape({
  email: yup.string().email('Please enter valid email').required('Please enter email id'),
});

function Login(props: any) {
  const { enqueueSnackbar } = useSnackbar();
  const _onLogin = async (values: LoginFormikState) => {
    try {
      const { email } = values;
        const payload: EntityLoginPayload = { email };
        const body = await props.onEntityLogin(payload);
        const { otpToken, role } = body;
        if (otpToken) props.history.push('verify-otp', { email, otpToken, role });
    } catch (error) {
      enqueueSnackbar((error as Error).message, {
        anchorOrigin: {
          vertical: 'top',
          horizontal: 'center',
        },
        variant: 'error',
      });
    }
  };

  const formik = useFormik<LoginFormikState>({
    initialValues,
    validationSchema,
    onSubmit: _onLogin,
    validateOnMount: false,
  });
  // console.log(formik.values);
  return (
    <div className="main_container">
      <div className="form_container">
        <img
          alt="jumna logo"
          src="/icons/unplanklogo.svg"
          style={{ width: '65px' }}
          className="margin_10_30"
        />
        <div className="signInHeadig margin_10_30">Sign in</div>
        <form onSubmit={formik.handleSubmit}>
          <div className="FormTxt margin_10_30">
            <TextInput
              label="Email Id"
              name="email"
              autoComplete="off"
              onChange={formik.handleChange}
              error={!!formik.errors.email && formik.touched.email}
              helperText={formik.touched.email && formik.errors.email}
            />

            <Button
              size="large"
              variant="contained"
              style={btnContinue}
              disabled={formik.isSubmitting}
              type="submit"
            >
              {`Generate OTP`}
            </Button>
          </div>
        </form>
      </div>
    </div>
  );
}

export default connect(null, { onLogin, onEntityLogin })(Login);
