import { Dialog, DialogTitle, DialogActions, Button, Box, DialogContent } from "@material-ui/core";
import { useSnackbar } from "notistack";
import { useDispatch } from "react-redux";
import { bankRegistrationApproval, contactSupport } from "../../redux-store/actions/request";

export default function DocumentPreview({
  viewFile,
  setViewFile,
  mimeType
}: { viewFile: string, setViewFile: (viewFile: string) => void, mimeType: string }) {

  return (
    <>
      <Dialog
        open={!!viewFile}
        onClose={() => setViewFile(viewFile)}
        className="documentPopUp"
        fullWidth={true}
        maxWidth={'sm'}
      >
        <Box className="close_img">
          <img
            src="/icons/close_menu.svg"
            alt="close"
            onClick={() => setViewFile('')}
          />
        </Box>
        <DialogContent>
          {mimeType === "application/pdf" ?
            <embed
              src={viewFile + "#toolbar=0"}
              type="application/pdf"
              className="pdfPreview"
            />
            : <img 
                src={viewFile} 
                className="imgPreview"
                alt="bank_document" />}
        </DialogContent>

      </Dialog>
    </>
  );
}
