import { Paper } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { connect, useSelector } from 'react-redux';
import {
  getAdminPendingStatements,
  getLenderPendingStatements,
  getLenderAccountBalance
} from '../../redux-store/actions/home';
import CustomTable from '../forms/Table';
import {
  ConfirmedStatementsHeaders,
  ConfirmedStatementsKeys,
  PendingStatementProps,
} from './types';
import { RootStateType } from '../../redux-store/reducers';
import Header from '../forms/headerBar';
import SideBar from '../forms/SideBar';
import { SIDENAV_ITEMS } from '../../utils/constants';
import LenderBar from './lenderBar';
import { useHistory } from 'react-router';
import { formatAmount } from '../../utils/commonFunctions';

function LoanAccounts({
  getLenderPendingStatements,
  getAdminPendingStatements,
  getLenderAccountBalance,
  user,
  ...props
}: PendingStatementProps) {
  const [loading, setLoading] = useState<boolean>(false);
  const [statement_data, setData] = useState([]);
  const [toalAmount,setTotal] = useState('');
  const history = useHistory();
  const { role } = user;

  useEffect(() => {
    setLoading(true);
    async function fetchLoanInfo() {
      try {
        const data =
          role === 'admin'
            ? await getAdminPendingStatements()
            : (await getLenderPendingStatements()) || [];
        const res = role === 'lender-user'  ? await getLenderAccountBalance() : {};
        setTotal(res?.pendingBalance);
        setData(data);
        setLoading(false);
      } catch (err) {
        throw err;
      }
    }
    fetchLoanInfo();
  }, [getLenderPendingStatements, getAdminPendingStatements, getLenderAccountBalance, role]);

  const { entity } = useSelector((store: RootStateType) => store.authReducer);

  return (
    <div className="flex_column">
      <Header />
      <div className="flex_row minHeight bgThemeColor">
        <SideBar list={SIDENAV_ITEMS} />
        <div className="loanWrapper">
          {user.role === 'lender-user' && <LenderBar entity={entity} />}
          <div className="loan_container">
            <div className="pendingStatementsTotalContainer">
              <div className="backNavContainer">
                <img
                  src="/icons/back_icon.svg"
                  alt="Go back"
                  style={{ marginBottom: 18, paddingRight: 10 }}
                  onClick={() => history.goBack()}
                />
                <div className="headingText">PENDING STATEMENT</div>
              </div>
              {role === 'lender-user' && (
                <div className="totalPendingBalance">
                  {`TOTAL :  ${formatAmount({ amount: toalAmount || 0 })}`}
                </div>
              )}
            </div>
            <Paper className="loanLogSec" square elevation={0}>
              {!loading ? (
                <>
                  <CustomTable
                    headers={ConfirmedStatementsHeaders}
                    path={ConfirmedStatementsKeys}
                    data={statement_data}
                    // title="Lender Cash Account"
                  />
                </>
              ) : (
                <p>{`Loading...`}</p>
              )}
            </Paper>
          </div>
        </div>
      </div>
    </div>
  );
}

export default connect(({ authReducer: { user } }: RootStateType) => ({ user }), {
  getLenderPendingStatements,
  getAdminPendingStatements,
  getLenderAccountBalance
})(LoanAccounts);
