import {
  FormControl,
  FormHelperText,
  IconButton,
  InputAdornment,
  InputLabel,
  TextField,
  TextFieldProps,
} from '@material-ui/core';
import { useState } from 'react';
import { Visibility, VisibilityOff } from '@material-ui/icons';

export function TextInput({
  name = '',
  label = '',
  error = false,
  helperText = '',
  ...props
}: TextFieldProps) {
  const id = `id-${name}`;
  return (
    <FormControl fullWidth>
      <TextField id={id} name={name} label={label} size="small" {...props} />
      {error && <FormHelperText error>{helperText}</FormHelperText>}
    </FormControl>
  );
}

export function SecureInput({
  label,
  showSecure = true,
  name = '',
  inputLabel = '',
  error = false,
  helperText = '',
  ...rest
}: TextFieldProps & { showSecure?: boolean; inputLabel?: string }) {
  const [showPassword, setPassword] = useState<boolean>(false);
  return (
    <FormControl>
      {/* {inputLabel && (
                <InputLabel htmlFor="standard-adornment-password">
                    {inputLabel}
                </InputLabel>
            )} */}
      <TextField
        {...rest}
        name={name}
        type={!showPassword ? 'password' : 'text'}
        id="outlined-adornment-password"
        label={inputLabel}
        // onPaste={(e) => {
        //     e.preventDefault();
        //     return false;
        // }}
        InputProps={{
          endAdornment: showSecure && (
            <InputAdornment position="end">
              <IconButton
                onClick={() => setPassword(!showPassword)}
                aria-label="toggle password visibility"
                edge="end"
              >
                {showPassword ? <Visibility /> : <VisibilityOff />}
              </IconButton>
            </InputAdornment>
          ),
        }}
      />
      {error && <FormHelperText error>{helperText}</FormHelperText>}
    </FormControl>
  );
}
