import { ConsumerLoanAccount, GetLoansResponseBody } from '../../components/dashboard/types';
import { CALL_API } from '../middleware';
import {
  GET_LOAN_DATES,
  GET_LOAN_DATES_SUCCESS,
  GET_LOAN_DETAILS,
  GET_LOAN_DETAILS_SUCCESS,
  LoanDetails,
  GET_LENDER_CONFIRMED_STATEMENT,
  GET_LENDER_CONFIRMED_STATEMENT_SUCCESS,
  GET_LENDER_PENDING_STATEMENT,
  GET_LENDER_PENDING_STATEMENT_SUCCESS,
  GET_ADMIN_PENDING_STATEMENT,
  GET_ADMIN_PENDING_STATEMENT_SUCCESS,
  GET_ADMIN_CONFIRMED_STATEMENT,
  GET_ADMIN_CONFIRMED_STATEMENT_SUCCESS,
  GET_LENDER_ACCOUNT_BALANCE,
  GET_LENDER_ACCOUNT_BALANCE_SUCCESS,
  GET_CONSUMER_LOAN_DETAILS,
GET_CONSUMER_LOAN_DETAILS_SUCCESS
} from '../types/home';

export const getLoans = (body: LoanDetails) => async (dispatch: any) => {
  try {
    const response = await dispatch({
      [CALL_API]: {
        url: `/loans/loans/get-loans?date=${body.date}`,
        method: 'GET',
        types: [GET_LOAN_DETAILS_SUCCESS, GET_LOAN_DETAILS],
        showLoader: true,
      },
    });
    return response.body as GetLoansResponseBody;
  } catch (error) {
    throw error;
  }
};

export const getConsumerLoansForMainLoan = (loanId: string, loanType: string) => async (dispatch: any) => {
  try {
    const response = await dispatch({
      [CALL_API]: {
        url: `/loans/loans/get-consumer-loans-for-loan`,
        method: 'GET',
        types: [GET_CONSUMER_LOAN_DETAILS, GET_CONSUMER_LOAN_DETAILS_SUCCESS],
        showLoader: true,
        params: {
          loanId,
          loanType
        }
      },
    });
    return response.body as ConsumerLoanAccount[];
  } catch (error) {
    throw error;
  }
};

export const getLoanDates = () => async (dispatch: any) => {
  try {
    return await dispatch({
      [CALL_API]: {
        url: `/loans/loans/get-loan-dates`,
        method: 'GET',
        types: [GET_LOAN_DATES_SUCCESS, GET_LOAN_DATES],
      },
    });
  } catch (error) {
    throw error;
  }
};

export const getLenderPendingStatements = (body: []) => async (dispatch: any) => {
  try {
    const response = await dispatch({
      [CALL_API]: {
        url: `/payments/statements/cash/lender/pending`,
        method: 'GET',
        types: [GET_LENDER_PENDING_STATEMENT, GET_LENDER_PENDING_STATEMENT_SUCCESS],
      },
    });
    return response.body;
  } catch (error) {
    throw error;
  }
};

export const getLenderConfirmedStatements = (body: []) => async (dispatch: any) => {
  try {
    const response = await dispatch({
      [CALL_API]: {
        url: `/payments/statements/cash/lender`,
        method: 'GET',
        types: [GET_LENDER_CONFIRMED_STATEMENT, GET_LENDER_CONFIRMED_STATEMENT_SUCCESS],
      },
    });
    return response.body;
  } catch (error) {
    throw error;
  }
};

export const getAdminConfirmedStatements = () => async (dispatch: any) => {
  try {
    const response = await dispatch({
      [CALL_API]: {
        url: `/payments/statements/cash/admin/confirmed`,
        method: 'GET',
        types: [GET_ADMIN_CONFIRMED_STATEMENT, GET_ADMIN_CONFIRMED_STATEMENT_SUCCESS],
      },
    });
    return response.body;
  } catch (error) {
    throw error;
  }
};

export const getAdminPendingStatements = () => async (dispatch: any) => {
  try {
    const response = await dispatch({
      [CALL_API]: {
        url: `/payments/statements/cash/admin/pending`,
        method: 'GET',
        types: [GET_ADMIN_PENDING_STATEMENT, GET_ADMIN_PENDING_STATEMENT_SUCCESS],
      },
    });
    return response.body;
  } catch (error) {
    throw error;
  }
};

export const getLenderAccountBalance = () => async (dispatch: any) => {
  try {
    const response = await dispatch({
      [CALL_API]: {
        url: `/payments/accounts/get_account_balance`,
        method: 'GET',
        types: [GET_LENDER_ACCOUNT_BALANCE, GET_LENDER_ACCOUNT_BALANCE_SUCCESS],
      },
    });
    return response.body;
  } catch (error) {
    throw error;
  }
};
