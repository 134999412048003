import { useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router';
import { RootStateType } from '../../redux-store/reducers';
import { SideNavProps } from '../../utils/constants';
import './SideBar.css';


export function NavItems({list}:{list:any}) {
  const { user } = useSelector((store: RootStateType) => store.authReducer);
  const location = useLocation();
  const history = useHistory();
  return(
    list
      .filter((l: SideNavProps) => l.tabAvailableFor.includes(user.role))
      .map(({ activeImage, inactive, label, keyRoute }: SideNavProps) => (
        <div
          onClick={() => {
            history.push(`${keyRoute}`);
          }}
          key={label}
          className={
            location?.pathname.includes(keyRoute) ? 'nav_item active' : 'nav_item'
          }
        >
          <img
            alt="img"
            src={location?.pathname === keyRoute ? activeImage : inactive}
          />
          <p className="nav_label">{label}</p>
        </div>
      ))
  );
}
function SideBar(props: any) {
  const { list = [] } = props;
  return (
    <div className="left_nav">
      <div>
        {/* <p style={collapseIcon}>
            <img src="/images/collapse.svg" style={{ cursor: "pointer" }} />
          </p> */}
        <NavItems list={list}/>
      </div>
    </div>
  );
}

export default SideBar;
