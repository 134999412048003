import { CALL_API } from '../middleware';
import {
  EntityLoginPayload,
  LOGIN,
  LoginPayload,
  LOGIN_SUCCESS,
  LOGOUT_SUCCESS,
  OtpValidationPayload,
  OTP_VALIDATION,
  OTP_VALIDATION_SUCCESS,
} from '../types/login';

export const onLogin = (body: LoginPayload) => async (dispatch: any) => {
  try {
    const { body: response, error } = await dispatch({
      [CALL_API]: {
        url: `/users/login/admin/email`,
        method: 'POST',
        types: [LOGIN_SUCCESS, LOGIN],
        body,
      },
    });
    if (error) {
      throw error;
    }
    return response;
  } catch (error) {
    throw error;
  }
};

export const onLogout = () => {
  return {
    type: LOGOUT_SUCCESS,
  };
};

export const onEntityLogin = (body: EntityLoginPayload) => async (dispatch: any) => {
  try {
    const { body: response, error } = await dispatch({
      [CALL_API]: {
        url: `/lenders/entity/login`,
        method: 'POST',
        types: [LOGIN_SUCCESS, LOGIN],
        body,
      },
    });
    if (error) {
      throw error;
    }
    return response;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const onEntityOtpVerification =
  (body: OtpValidationPayload) => async (dispatch: any) => {
    try {
      const { body: response, error } = await dispatch({
        [CALL_API]: {
          url: `/lenders/entity/verify-otp`,
          method: 'POST',
          types: [OTP_VALIDATION_SUCCESS, OTP_VALIDATION],
          body,
        },
      });
      if (error) {
        throw error;
      }
      return response;
    } catch (error) {
      throw error;
    }
  };
