export const LOGIN = 'LOGIN';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';

export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS';

export interface LoginPayload {
  email: string;
  password: string;
}

export interface EntityLoginPayload {
  email: string;
}

export interface EntityLoginResponse {
  otpToken: string;
  role: string;
}

export interface VerifyOtpPayload {
  otpToken: string;
  otp: string;
  role: string;
}

export interface VerifyOtpResponse {
  id: string;
  entityId: string;
  userId: string;
  isActive: boolean;
  user: {
    firstName: string;
    lastName: string;
    email: string;
    phone: string;
    role: string;
    emailVerified: boolean;
    phoneVerified: boolean;
    passwordExists: boolean;
  };
  createdAt: string;
  updatedAt: string;
  token: string;
}

export interface OtpValidationPayload {
  otpToken: string;
  otp: string;
  role: string;
}
export interface AuthState {
  token: string | null;
}
interface OnLoginAction {
  type: typeof LOGIN_SUCCESS;
  body: LoginPayload;
}

export type AuthTypes = OnLoginAction;

export const OTP_VALIDATION = 'OTP_VALIDATION';
export const OTP_VALIDATION_SUCCESS = 'OTP_VALIDATION_SUCCESS';

export const SHOW_TOAST = 'SHOW_TOAST';
export const STOP_TOAST = 'STOP_TOAST';
