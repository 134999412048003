import { START_LOADING, STOP_LOADING } from '../types/loader';
import { SHOW_TOAST, STOP_TOAST } from '../types/login';

const initialState = {
  showLoader: 0,
};

export default function loaderReducer(state = initialState, action: any) {
  switch (action.type) {
    case START_LOADING: {
      return { ...state, showLoader: ++state.showLoader };
    }
    case STOP_LOADING: {
      return { ...state, showLoader: Math.max(0, --state.showLoader) };
    }
    default:
      return state;
  }
}
