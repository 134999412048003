import { CALL_API } from '../middleware';
import {
  ADD_ENTITY_USER,
  ADD_ENTITY_USER_SUCCESS,
  CREATE_ENTITY,
  CREATE_ENTITY_SUCCESS,
  EntityPayload,
  EntityUser,
  GET_ENTITIES,
  GET_ENTITIES_SUCCESS,
  GET_ENTITY_USERS,
  GET_ENTITY_USERS_SUCCESS,
  UPDATE_ENTITY_STATUS,
  UPDATE_ENTITY_STATUS_SUCCESS,
  UPDATE_ENTITY_USER_STATUS,
  UPDATE_ENTITY_USER_STATUS_SUCCESS,
} from '../types/entity';

export const getEntities = () => async (dispatch: any) => {
  try {
    const response = await dispatch({
      [CALL_API]: {
        url: `/lenders/entity/list`,
        method: 'GET',
        types: [GET_ENTITIES_SUCCESS, GET_ENTITIES],
        showLoader: true,
      },
    });
    return response.body;
  } catch (error) {
    throw error;
  }
};

export const createEntity = (body: EntityPayload) => async (dispatch: any) => {
  try {
    const response = await dispatch({
      [CALL_API]: {
        url: `/lenders/entity/create`,
        method: 'POST',
        types: [CREATE_ENTITY_SUCCESS, CREATE_ENTITY],
        body,
        showLoader: true,
      },
    });
    if (response.error) {
      throw response.error;
    }
    return response.body;
  } catch (error) {
    throw error;
  }
};

export const updateEntityStatus =
  (entityId: string, isActive: boolean) => async (dispatch: any) => {
    try {
      const response = await dispatch({
        [CALL_API]: {
          url: `/lenders/entity/${entityId}/update-active-status`,
          method: 'POST',
          body: { isActive },
          types: [UPDATE_ENTITY_STATUS_SUCCESS, UPDATE_ENTITY_STATUS],
          parameters: { entityId },
        },
      });
      return response.body;
    } catch (error) {
      throw error;
    }
  };

export const getEntityUsers = (entityId: string) => async (dispatch: any) => {
  try {
    const response = await dispatch({
      [CALL_API]: {
        url: `/lenders/entity/${entityId}/list`,
        method: 'GET',
        types: [GET_ENTITY_USERS_SUCCESS, GET_ENTITY_USERS],
        showLoader: true,
      },
    });
    return response.body;
  } catch (error) {
    throw error;
  }
};

export const addEntityUser =
  (entityId: string, body: EntityUser) => async (dispatch: any) => {
    try {
      const response = await dispatch({
        [CALL_API]: {
          url: `/lenders/entity/${entityId}/add-entity-user`,
          method: 'POST',
          types: [ADD_ENTITY_USER_SUCCESS, ADD_ENTITY_USER],
          body,
          showLoader: true,
        },
      });
      if (response.error) {
        throw response.error;
      }
      return response.body;
    } catch (error) {
      throw error;
    }
  };

export const updateEntityUserStatus =
  (entityId: string, entityUserId: string, isActive: boolean) =>
  async (dispatch: any) => {
    try {
      const response = await dispatch({
        [CALL_API]: {
          url: `/lenders/entity/${entityId}/update-active-status/${entityUserId}`,
          method: 'POST',
          types: [UPDATE_ENTITY_USER_STATUS_SUCCESS, UPDATE_ENTITY_USER_STATUS],
          body: { isActive },
        },
      });
      return response.body;
    } catch (error) {
      throw error;
    }
  };
