import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Input,
  InputAdornment,
  InputLabel,
  Radio,
  RadioGroup,
} from '@material-ui/core';
import { useFormik, validateYupSchema, yupToFormErrors } from 'formik';
import { useSnackbar } from 'notistack';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import * as yup from 'yup';
import { createEntity } from '../../redux-store/actions/entity';
import { EntityPayload } from '../../redux-store/types/entity';

const validationSchema = yup.object({
  entityType: yup.string().required('Entity type is required'),
  name: yup.string().trim().test('name', (value, context) => {
    const { options, createError, path } = context;
    const { entityType = '' } = options.context || ({} as EntityPayload);
    if (!value) {
      return createError({
        message: `${entityType === 'lender' ? 'Lender name' : 'Bank name'} is required`,
        path,
      });
    }
    return true;
  }),
  commitmentAmount: yup
    .string()
    // .typeError('Commitment amount should be a number')
    .test('commitmentAmount', (value, context) => {
      const { options, createError, path } = context;
      const { entityType = '' } = options.context || ({} as EntityPayload);
      if (entityType === 'lender') {
        if (value === '0') {
          return createError({
            message: 'Commitment amount should not be zero',
            path,
          });
        }
        if (!value) {
          return createError({
            message: 'Commitment amount is required',
            path,
          });
        }
        if(!/^\d+$/.test(value)){
          return createError({
            message: 'Invalid commitment amount',
            path,
          });
        }
      }
      return true;
    })

});

export default function CreateEntity({
  open,
  handleClose,
}: {
  open: boolean;
  handleClose: () => void;
}) {
  const dispatch = useDispatch();
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const formik = useFormik({
    initialValues: {
      entityType: '',
      name: '',
      commitmentAmount: 0,
    },
    validate: (values: EntityPayload) => {
      try {
        validateYupSchema(values, validationSchema, true, values);
      } catch (e) {
        return yupToFormErrors(e);
      }
    },
    onSubmit: async (values, { resetForm }) => {
      const { entityType, name, commitmentAmount } = values;
      try {
        handleClose();
        await dispatch(
          createEntity({
            entityType,
            name,
            ...(entityType === 'lender' && { commitmentAmount: +commitmentAmount }),
          }),
        );
        // resetForm();

        enqueueSnackbar(
          `${entityType === 'lender' ? 'Lender' : 'Bank'} added successfully`,
          {
            anchorOrigin: {
              vertical: 'top',
              horizontal: 'center',
            },
            variant: 'success',
          },
        );
      } catch (error) {
        enqueueSnackbar((error as Error).message, {
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'center',
          },
          variant: 'error',
        });
      } finally {
        resetForm();
      }
    },
  });
  // console.log(formik.values)

  const clickOutsideHandler = (event: any, reason: string) => {
    if (reason === 'backdropClick') {
      // formik.setFieldValue('entityType', '');
      // formik.setFieldValue('name', '');
      // formik.setFieldValue('commitmentAmount', '');
      formik.resetForm();
    }
    handleClose();
  };

  const closeDialog = () => {
    // formik.setFieldValue('entityType', '');
    // formik.setFieldValue('name', '');
    // formik.setFieldValue('commitmentAmount', '');
    formik.resetForm();
    handleClose();
  };

  useEffect(() => {
    if (formik?.values?.entityType === 'bank') {
      formik.setFieldValue('name', '');
      formik.setFieldValue('commitmentAmount', '');
    }
    if (formik?.values?.entityType === 'lender') {
      formik.setFieldValue('name', '');
      formik.setFieldValue('commitmentAmount', '');
    }
  }, [formik?.values?.entityType]);

  return (
    <Dialog
      open={open}
      onClose={clickOutsideHandler}
      style={{ padding: 40 }}
      fullWidth={true}
      maxWidth={'xs'}
    >
      <Box className="modalPopup">
        <Box onClick={closeDialog}>
          <img
            src="/icons/close_menu.svg"
            alt="close"
            style={{ float: 'right', cursor: 'pointer' }}
          />
        </Box>
        <DialogTitle>Add Entity</DialogTitle>
        <form onSubmit={formik.handleSubmit}>
          <DialogContent>
            <DialogContentText>Type of Entity</DialogContentText>
            <DialogContentText>
              <FormControl
                error={formik.touched.entityType && Boolean(formik.errors.entityType)}
              >
                <RadioGroup
                  row
                  id="entityType"
                  name="entityType"
                  defaultValue="Bank"
                  value={formik.values.entityType}
                  onChange={formik.handleChange}
                >
                  <FormControlLabel
                    value="bank"
                    control={<Radio size="small" color="primary" />}
                    label="Bank"
                  />
                  <FormControlLabel
                    value="lender"
                    control={<Radio size="small" color="primary" />}
                    label="Lender"
                  />
                </RadioGroup>
                <FormHelperText>
                  {formik.touched.entityType && formik.errors.entityType}
                </FormHelperText>
              </FormControl>
            </DialogContentText>
            <FormControl
              variant="standard"
              fullWidth
              margin="dense"
              error={formik.touched.name && Boolean(formik.errors.name)}
            >
              <InputLabel htmlFor="component-simple">Name</InputLabel>
              <Input
                id="name"
                value={formik.values.name}
                onChange={formik.handleChange}
              />
              <FormHelperText>{formik.touched.name && formik.errors.name}</FormHelperText>
            </FormControl>
            {formik?.values?.entityType === 'lender' && (
              <FormControl
                variant="standard"
                fullWidth
                margin="dense"
                error={
                  formik.touched.commitmentAmount &&
                  Boolean(formik.errors.commitmentAmount)
                }
              >
                <InputLabel htmlFor="component-simple">Commitment Amount</InputLabel>
                <Input
                  id="commitmentAmount"
                  value={formik.values.commitmentAmount}
                  onChange={formik.handleChange}
                  startAdornment={<InputAdornment position="start">$</InputAdornment>}
                />
                <FormHelperText>
                  {formik.touched.commitmentAmount && formik.errors.commitmentAmount}
                </FormHelperText>
              </FormControl>
            )}
          </DialogContent>
          <DialogActions className="margin_top_10">
            <Button
              variant="contained"
              color="primary"
              type="submit"
              disabled={formik.isSubmitting}
            >
              Save
            </Button>
          </DialogActions>
        </form>
      </Box>
    </Dialog>
  );
}
