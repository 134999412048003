import { ResponseBody } from '../middleware';
import {
  AuthState,
  AuthTypes,
  LOGIN_SUCCESS,
  LOGOUT_SUCCESS,
  OTP_VALIDATION_SUCCESS,
} from '../types/login';

const intiState: Partial<AuthState> = {
  token: null,
};
function authReducer(state = intiState, action: AuthTypes & ResponseBody) {
  switch (action.type) {
    case LOGIN_SUCCESS:
    case OTP_VALIDATION_SUCCESS: {
      return {
        ...state,
        ...action.body,
      };
    }
    case LOGOUT_SUCCESS: {
      return {
        ...intiState,
      };
    }
    default:
      return state;
  }
}

export default authReducer;
