import { Paper } from '@material-ui/core';
import { useEffect, useState } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import {
  getConsumerLoansForMainLoan,
  getLoanDates,
  getLoans,
} from '../../redux-store/actions/home';
import moment from 'moment';
import CustomTable, { Row } from '../forms/Table';
import {
  headers,
  StateProps,
  keys,
  cosumerValues,
  headersForAdmin,
  keysForAdmin,
  cosumerValuesForBank,
  ActiveLoan,
  ConsumerLoanAccount,
} from './types';
import { RootStateType } from '../../redux-store/reducers';
import Header from '../forms/headerBar';
import SideBar from '../forms/SideBar';
import { SIDENAV_ITEMS } from '../../utils/constants';
import LenderBar from './lenderBar';

function LoanAccounts({ getLoans, getLoanDates, user, ...props }: StateProps) {
  const [data, setLoanData] = useState<ActiveLoan>({
    activeLoans: [],
  });
  const [date, setDate] = useState<string>(moment(new Date()).format('l'));
  const [loading, setLoading] = useState<boolean>(false);
  const dispatch = useDispatch();

  const { entity } = useSelector((store: RootStateType) => store.authReducer);

  useEffect(() => {
    setLoading(true);
    async function fetchLoanInfo() {
      try {
        const { activeLoans } = (await getLoans({ date })) || {};
        setLoanData({ activeLoans });
        setLoading(false);
      } catch (err) {
        throw err;
      }
    }
    fetchLoanInfo();
  }, [getLoans, date]);

  const fetchConsumerLoans = async (loanId: string) => {
    const consumerLoans = (await dispatch(
      getConsumerLoansForMainLoan(loanId, 'active'),
    )) as unknown as ConsumerLoanAccount[];

    if (!consumerLoans.length) {
      return null;
    }

    return consumerLoans.map((loan, index) => (
      <Row
        key={index}
        path={user.role === 'admin' ? cosumerValues : cosumerValuesForBank}
        headers={user.role === 'admin' ? headersForAdmin : headers}
        row={loan}
        index={index}
      ></Row>
    ));
  };

  const { activeLoans = [] } = data;
  return (
    <div className="flex_column">
      <Header />
      <div className="flex_row minHeight bgThemeColor">
        <SideBar list={SIDENAV_ITEMS} />
        <div className="loanWrapper">
          {user.role === 'lender-user' && <LenderBar entity={entity} />}
          <div className="loan_container">
            <div className="headingText">ACTIVE LOAN PORTFOLIO</div>
            <Paper className="loanLogSec" square elevation={0}>
              {/* <div>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <KeyboardDatePicker
                    disableToolbar
                    autoOk
                    variant="inline"
                    format="MM/dd/yyyy"
                    margin="normal"
                    id="date-picker-inline"
                    label="Select Date"
                    value={date}
                    onChange={(date) => setDate(() => moment(date).format('l'))}
                    KeyboardButtonProps={{
                      'aria-label': 'change date',
                    }}
                  />
                </MuiPickersUtilsProvider>
              </div> */}
              {/* date selector */}
              {/* <div className="flex_column" style={{ alignItems: 'flex-end' }}>
            <Select
              placeholder="Date"
              onChange={({ target: { value } }: any) => setDate(value)}
              value={date}
            >
              {loanDates.map((date) => (
                <MenuItem key={date} value={moment(date).format('l')}>
                  {moment(date).format('l')}
                </MenuItem>
              ))}
            </Select>
          </div> */}
              {!loading ? (
                <>
                  <CustomTable
                    headers={user.role === 'admin' ? headersForAdmin : headers}
                    data={activeLoans}
                    path={user.role === 'admin' ? keysForAdmin : keys}
                    title="Active Loan Portfolio"
                    enableGroups={true}
                    fetchDataForRow={fetchConsumerLoans}
                  />
                </>
              ) : (
                <p>{`Loading...`}</p>
              )}
            </Paper>
          </div>
        </div>
      </div>
    </div>
  );
}

export default connect(({ authReducer: { user } }: RootStateType) => ({ user }), {
  getLoans,
  getLoanDates,
})(LoanAccounts);
