import React from 'react';
import { PersistGate } from 'redux-persist/es/integration/react';
import { SnackbarProvider } from 'notistack';
import './App.scss';
import { Provider } from 'react-redux';
import store, { persistor } from './store';
import AppRoutes from './routes';

export default function App() {
  return (
    <Provider store={store}>
      <PersistGate persistor={persistor}>
        <SnackbarProvider
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
        >
          <div style={{ width: '100%', height: '100vh' }}>
            <AppRoutes />
          </div>
        </SnackbarProvider>
      </PersistGate>
    </Provider>
  );
}
