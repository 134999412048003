import { Dialog, DialogTitle, DialogActions, Button, Box } from "@material-ui/core";
import { useSnackbar } from "notistack";
import { useDispatch } from "react-redux";
import { bankRegistrationApproval, contactSupport } from "../../redux-store/actions/request";

export default function ConfirmationDialog({
    openDialog,
    setDialog,
    action,
}: {openDialog: boolean, setDialog: (openDialog: boolean) => void, action: any}) {
  const { registrationId, bankRegistrationId, type, email, businessName, status } = action;
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();

  async function actionHandler() {
    if (type === "approved") {
        await dispatch(bankRegistrationApproval(registrationId, bankRegistrationId, type));
        enqueueSnackbar(
          `Approved successfully`,
          {
            anchorOrigin: {
              vertical: 'top',
              horizontal: 'center',
            },
            variant: 'success',
          },
        );
    }
    if (type === "rejected") {
        await dispatch(bankRegistrationApproval(registrationId, bankRegistrationId, type));
        enqueueSnackbar(
          `Rejected successfully`,
          {
            anchorOrigin: {
              vertical: 'top',
              horizontal: 'center',
            },
            variant: 'success',
          },
        );
    }
    if (type === "contact-support"){
        await dispatch(contactSupport(email, businessName));
        enqueueSnackbar(
          `Email sent`,
          {
            anchorOrigin: {
              vertical: 'top',
              horizontal: 'center',
            },
            variant: 'success',
          },
        );
    }
    if (type === "nudge") {
      await dispatch(contactSupport(email, businessName, status));
      enqueueSnackbar(
        `Email sent`,
        {
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'center',
          },
          variant: 'success',
        },
      );
    }
    setDialog(false)
  }

  return (
    <>
      <Dialog
        open={openDialog}
        onClose={() => setDialog(true)}
        className="actionPopUp"
        fullWidth={true}
        maxWidth={'xs'}
      >
        <Box className="close_img">
          <img
            src="/icons/close_menu.svg"
            alt="close"
            onClick={() => setDialog(false)}
          />
        </Box>
        <DialogTitle>Are you sure?</DialogTitle>
        <DialogActions>
          <Button 
            color="primary" 
            variant="contained" 
            fullWidth 
            onClick={actionHandler}
            style={{marginRight: 10}}
          >
            Confirm
          </Button>
          <Button
            color="primary" 
            variant="outlined" 
            fullWidth
            onClick={() => setDialog(false)}
          >
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
