export interface SideNavProps {
  activeImage: string;
  inactive: string;
  label: string;
  keyRoute: string;
  tabAvailableFor: string[];
}

export const SIDENAV_ITEMS: SideNavProps[] = [
  {
    activeImage: '/icons/user_management_active_icon.svg',
    inactive: '/icons/user_management_inactive_icon.svg',
    label: 'User Management',
    keyRoute: '/user-management',
    tabAvailableFor: ['admin'],
  },
  {
    activeImage: '/icons/active_loan_portfolio_active.svg',
    inactive: '/icons/acitve_loan_portfolio_inactive.svg',
    label: 'Active Loan Portfolio',
    keyRoute: '/active-loan',
    tabAvailableFor: ['admin', 'lender-user', 'bank-user'],
  },
  {
    activeImage: '/icons/matured_loan_portfolio_active.svg',
    inactive: '/icons/matured_loan_portfolio_inactive.svg',
    label: 'Matured Loan Portfolio',
    keyRoute: '/matured-loan',
    tabAvailableFor: ['admin', 'lender-user', 'bank-user'],
  },
  {
    activeImage: '/icons/cash_account_active.svg',
    inactive: '/icons/cash_account_inactive.svg',
    label: 'Cash Account',
    keyRoute: '/cash-account',
    tabAvailableFor: ['admin', 'lender-user', 'bank-user'],
  },
  {
    activeImage: '/icons/terms_of_use_active.svg',
    inactive: '/icons/terms_of_use_inactive.svg',
    label: 'Terms of Use',
    keyRoute: '/terms-of-use',
    tabAvailableFor: ['lender-user'],
  },
  {
    activeImage: '/icons/privacy_policy_active.svg',
    inactive: '/icons/privacy_policy_inactive.svg',
    label: 'Privacy Policy',
    keyRoute: '/privacy-policy',
    tabAvailableFor: ['lender-user'],
  },
  // {
  //   activeImage: '/icons/admin_active.svg',
  //   inactive: '/icons/admin_inactive.svg',
  //   label: 'Settings',
  //   keyRoute: '/settings',
  //   tabAvailableFor: ['admin', 'lender-user', 'bank-user'],
  // },
  {
    activeImage: '/icons/merchants_list_active.svg',
    inactive: '/icons/merchants_list_inactive.svg',
    label: 'Merchants List',
    keyRoute: '/merchants',
    tabAvailableFor: ['admin'],
  },
];

export const PENDING_ENGIMA_VERIFICATION = 'pending-enigma-verification';
export const ENGIMA_REJECTED = 'enigma-rejected';
export const ADMIN_REJECTED = 'admin-rejected';
export const PENDING_REGISTRATION_DETAILS = 'pending-registration-details';
export const PENDING_TNC_ACCEPTANCE = 'pending-tnc-acceptance';
export const PENDING_PASSWORD_CREATION = 'pending-password-creation';
export const PENDING_BANK_APPROVAL = 'pending-bank-approval';
export const LIVE = 'live';
export const VERIFICATION_UNDER_WAY = 'verification-under-way';
export const APPROVED = 'approved';
export const REJECTED = 'rejected';
export const PENDING_STORE_FRONT_CREATION = 'pending-store-front-creation'
export const PENDING_DEVICE_LINKING = 'pending-device-linking'
export const PENDING_PERSONAL_VERIFICATION = 'pending-personal-verification'

export const BUSINESS_CATEGORIES: { [key: string]: string } = {
  [PENDING_ENGIMA_VERIFICATION]: 'Pending Engima Verification',
  [ENGIMA_REJECTED]: 'Declined',
  [ADMIN_REJECTED]: 'Admin rejected',
  [PENDING_REGISTRATION_DETAILS]: 'Pending registration details',
  [PENDING_TNC_ACCEPTANCE]: 'Pending TnC Acceptance',
  [PENDING_PASSWORD_CREATION]: 'Pending Password Creation',
  [PENDING_BANK_APPROVAL]: 'Pending Bank Verification',
  [LIVE]: 'Live',
  [VERIFICATION_UNDER_WAY]: 'Bank Verification Underway',
  [APPROVED]: 'Approved',
  [REJECTED]: 'Rejected',
  [PENDING_STORE_FRONT_CREATION]: 'Pending Storefront Creation',
  [PENDING_DEVICE_LINKING]: 'Pending Device Linking',
  [PENDING_PERSONAL_VERIFICATION]: 'Pending Personal Verification'
};

export const ACCOUNT_STATUS_COLOR: { [key: string]: string } = {
  [PENDING_ENGIMA_VERIFICATION]: '#F1915A',
  [ENGIMA_REJECTED]: '#EB5757',
  [ADMIN_REJECTED]: '#EB5757',
  [PENDING_REGISTRATION_DETAILS]: '#F1915A',
  [PENDING_TNC_ACCEPTANCE]: '#F1915A',
  [PENDING_PASSWORD_CREATION]: '#F1915A',
  [PENDING_BANK_APPROVAL]: '#F1915A',
  [LIVE]: '#2DC093',
  [VERIFICATION_UNDER_WAY]: '#F1915A',
  [APPROVED]: '#F1915A',
  [REJECTED]: '#EB5757',
  [PENDING_STORE_FRONT_CREATION]: '#F1915A',
  [PENDING_DEVICE_LINKING]: '#F1915A',
  [PENDING_PERSONAL_VERIFICATION]: '#F1915A'
};
