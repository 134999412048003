import {  Paper } from '@material-ui/core';
import { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { getAdminConfirmedStatements, getLenderConfirmedStatements } from '../../redux-store/actions/home';
import CustomTable from '../forms/Table';
import {
ConfirmedStatementsHeaders,
ConfirmedStatementsKeys,
ConfimedStatementProps,
} from './types';
import { RootStateType } from '../../redux-store/reducers';

function LoanAccounts({
  getLenderConfirmedStatements,
  getAdminConfirmedStatements,
  user,
  ...props
}: ConfimedStatementProps) {
  const [loading, setLoading] = useState<boolean>(false);
  const [statement_data, setData] = useState([]);

  useEffect(() => {
    setLoading(true);
    async function fetchLoanInfo() {
      const { role } = user;
      try {
        const data =
          role === 'admin'
            ? await getAdminConfirmedStatements()
            : (await getLenderConfirmedStatements()) || [];
        setData(data);
        setLoading(false);
      } catch (err) {
        throw err;
      }
    }
    fetchLoanInfo();
  }, [getLenderConfirmedStatements, getAdminConfirmedStatements, user]);


  return (
    <Paper className="loanLogSec" square elevation={0}>
      {!loading ? (
        <>
          <CustomTable
            headers={ConfirmedStatementsHeaders}
            path={ConfirmedStatementsKeys}
            data={statement_data}
          />
        </>
      ) : (
        <p>{`Loading...`}</p>
      )}
    </Paper>
    // <div className="flex_column">
    //   <Header />
    //   <div className="flex_row minHeight bgThemeColor">
    //     <SideBar list={SIDENAV_ITEMS} />
    //     <div className='loanWrapper'>
    //       {(user.role === 'lender-user') && <LenderBar entity={entity} />}
    //       <div className="loan_container">
    //         <div className="headingText">CONFIRMED STATEMENTS</div>
    //         <Paper className="loanLogSec" square elevation={0}>
    //           {!loading ? (
    //             <>
    //               <CustomTable
    //                headers={ConfirmedStatementsHeaders}
    //                path={ConfirmedStatementsKeys}
    //                data={statement_data}
    //               />
    //             </>
    //           ) : (
    //             <p>{`Loading...`}</p>
    //           )}
    //         </Paper>
    //       </div>
    //     </div>
    //   </div>
    // </div>
  );
}

export default connect(({ authReducer: { user } }: RootStateType) => ({ user }), {
  getLenderConfirmedStatements,
  getAdminConfirmedStatements,
})(LoanAccounts);
