import { CALL_API } from '../middleware';

import {
  SET_REGISTRATIONS,
  RegistrationParams,
  SET_REGISTRATIONS_SUCCESS,
  APPROVE_REGISTRATION,
  REJECT_REGISTRATION,
  REJECT_REGISTRATION_SUCCESS,
  APPROVE_REGISTRATION_SUCCESS,
  GET_REGISTRATION,
  GET_REGISTRATION_SUCCESS,
  GET_BUSSINESS_CATEGORY,
  GET_BUSSINESS_CATEGORY_SUCCESS,
  BANK_REGISTRATION_APPROVAL,
  BANK_REGISTRATION_APPROVAL_SUCCESS,
  BANK_REGISTRATION_DOCUMENT_REQUEST_SUCCESS,
  BANK_REGISTRATION_DOCUMENT_REQUEST,
  SEND_EMAIL,
  SEND_EMAIL_SUCCESS,
} from '../types/request';

export const getAllRegistrations =
  (params: RegistrationParams) => async (dispatch: any) => {
    try {
      const response = await dispatch({
        [CALL_API]: {
          url: `/businesses/businesses/registrations/list`,
          method: 'GET',
          params: params,
          types: [SET_REGISTRATIONS_SUCCESS, SET_REGISTRATIONS],
        },
      });

      return response.body;
    } catch (error) {
      throw error;
    }
  };

export const getRegistrationDetail =
  (registrationId: string) => async (dispatch: any) => {
    try {
      const response = await dispatch({
        [CALL_API]: {
          url: `/businesses/businesses/registrations/${registrationId}`,
          method: 'GET',
          types: [GET_REGISTRATION_SUCCESS, GET_REGISTRATION],
        },
      });

      return response.body;
    } catch (error) {
      throw error;
    }
  };

export const approveRegistration = (registrationId: string) => async (dispatch: any) => {
  try {
    const response = await dispatch({
      [CALL_API]: {
        url: `/businesses/businesses/registrations/${registrationId}/approve`,
        method: 'POST',
        types: [APPROVE_REGISTRATION_SUCCESS, APPROVE_REGISTRATION],
        parameters: { registrationId },
      },
    });

    return response.body;
  } catch (error) {
    throw error;
  }
};

export const rejectRegistration = (registrationId: string) => async (dispatch: any) => {
  try {
    const response = await dispatch({
      [CALL_API]: {
        url: `/businesses/businesses/registrations/${registrationId}/reject`,
        method: 'POST',
        types: [REJECT_REGISTRATION_SUCCESS, REJECT_REGISTRATION],
        parameters: { registrationId },
      },
    });

    return response.body;
  } catch (error) {
    throw error;
  }
};

export const getBusinessCategories = () => async (dispatch: any) => {
  try {
    return await dispatch({
      [CALL_API]: {
        url: `/businesses/categories`,
        showLoader: true,
        method: 'GET',
        types: [GET_BUSSINESS_CATEGORY, GET_BUSSINESS_CATEGORY_SUCCESS],
      },
    });
  } catch (error) {
    throw error;
  }
};


export const bankRegistrationApproval = (registrationId: string, bankRegistrationId: string, status: string) => async (dispatch: any) => {
  try {
    const response = await dispatch({
      [CALL_API]: {
        url: `/businesses/businesses/bank-registrations/${bankRegistrationId}/update-status`,
        method: 'POST',
        types: [BANK_REGISTRATION_APPROVAL_SUCCESS, BANK_REGISTRATION_APPROVAL],
        parameters: { registrationId, bankRegistrationId },
        body: {status}
      },
    });

    return response.body;
  } catch (error) {
    throw error;
  }
};



export const bankRegistrationDocumentRequest = (registrationId: string, bankRegistrationId: string) => async (dispatch: any) => {
  try {
    const response = await dispatch({
      [CALL_API]: {
        url: `/businesses/businesses/bank-registrations/${bankRegistrationId}/request-documents`,
        method: 'POST',
        types: [BANK_REGISTRATION_DOCUMENT_REQUEST_SUCCESS, BANK_REGISTRATION_DOCUMENT_REQUEST],
        parameters: { registrationId, bankRegistrationId },
      },
    });

    return response.body;
  } catch (error) {
    throw error;
  }
};



export const contactSupport = (email: string, businessName: string, status?: string) => async (dispatch: any) => {
  try {
    const response = await dispatch({
      [CALL_API]: {
        url: `/businesses/businesses/bank-registrations/send-emails`,
        method: 'POST',
        types: [SEND_EMAIL_SUCCESS, SEND_EMAIL],
        body: {email, status, businessName}
      },
    });

    return response.body;
  } catch (error) {
    throw error;
  }
};