import {
  Button,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@material-ui/core';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import {
  approveRegistration,
  bankRegistrationApproval,
  bankRegistrationDocumentRequest,
  getAllRegistrations,
  rejectRegistration,
} from '../../redux-store/actions/request';
import { RegistrationsState } from '../../redux-store/reducers/registration';
import {
  ACCOUNT_STATUS_COLOR,
  BUSINESS_CATEGORIES,
  PENDING_BANK_APPROVAL,
  PENDING_ENGIMA_VERIFICATION,
  PENDING_STORE_FRONT_CREATION,
  SIDENAV_ITEMS,
  VERIFICATION_UNDER_WAY,
} from '../../utils/constants';
import SideBar from '../forms/SideBar';
import { onLogout } from '../../redux-store/actions/login';
import { Pagination } from '@material-ui/lab';
import { Registration, SET_PARAMETERS } from '../../redux-store/types/request';
import { ParametersState } from '../../redux-store/reducers/parameters';
import Header from '../forms/headerBar';
import { formatDate } from '../../utils/commonFunctions';
import ConfirmationDialog from './ConfirmationDialog';
import { useSnackbar } from 'notistack';

export default function MerchantList(props: any) {
  const [openDialog, setDialog] = useState(false);
  const [action, setAction] = useState({
    registrationId: '',
    bankRegistrationId: '',
    type: '',
    email: '',
    businessName: '',
    status: ''
  });
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();
  const history = useHistory();
  const registrationList = useSelector(
    (state: { registrations: RegistrationsState }) => state.registrations,
  );

  const sortedList = registrationList.docs.sort((a, b) => new Date(b.updatedAt).getTime() - new Date(a.updatedAt).getTime());
  const params = useSelector(
    (state: { parameters: ParametersState }) => state.parameters,
  );


  const approveBusinessRegistration = async (registrationId: string) => {
    await dispatch(approveRegistration(registrationId));
  };

  const rejectBusinessRegistration = async (registrationId: string) => {
    await dispatch(rejectRegistration(registrationId));
  };


  const approveBankRegistration = (registrationId: string, bankRegistrationId: string) => {
    if (bankRegistrationId) {
      setDialog(true)
      setAction({ ...action, registrationId, bankRegistrationId, type: 'approved' })
    }
  };

  const rejectBankRegistration = (registrationId: string, bankRegistrationId: string) => {
    if (bankRegistrationId) {
      setDialog(true)
      setAction({ ...action, registrationId, bankRegistrationId, type: 'rejected' })
    }
  };


  const requestBankDocument = async (registrationId: string, bankRegistrationId: string) => {
    if (bankRegistrationId) {
      await dispatch(bankRegistrationDocumentRequest(registrationId, bankRegistrationId));
      enqueueSnackbar(
        `Document Requested`,
        {
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'center',
          },
          variant: 'success',
        },
      );
    }
  };


  const contactSupport = (email: string, businessName: string) => {
    setDialog(true)
    setAction({ ...action, email, businessName, type: 'contact-support' })
  }


  const sendNudgeMail = (email: string, businessName: string, status: string) => {
    setDialog(true)
    setAction({ ...action, email, businessName, status, type: 'nudge' })
  }



  const showRegistrationDetail = (registrationId: string) => {
    history.push(`/merchants/${registrationId}/enigma-json`);
  };

  const showDirectIdJSON = (registrationId: string, accounts: string) => {
    history.push(`/merchants/${registrationId}/directid-json`, { accounts })
  }


  const showDocumentList = (registrationId: string, bankDocuments: string) => {
    history.push(`/merchants/${registrationId}/documents`, { bankDocuments })
  }

  useEffect(() => {
    async function fetch() {
      await dispatch(getAllRegistrations(params));
    }
    fetch();
  }, [params?.page, dispatch]);


  return (
    <>
      <div className="flex_column">
        <Header />
        <div className="flex_row minHeight bgThemeColor">
          <SideBar history={props.history} list={SIDENAV_ITEMS} />
          <div className='loanWrapper'>
            <div className="loan_container">
              <div className="headingText">MERCHANT LIST</div>
              <Paper square elevation={0}>
                <TableContainer className="merchantTable">
                  <Table aria-label="simple table">
                    <TableHead>
                      <TableRow className="textBold">
                        <TableCell>S. No.</TableCell>
                        <TableCell>Full Name</TableCell>
                        <TableCell>Title</TableCell>
                        <TableCell>Business Category</TableCell>
                        <TableCell>Business Name</TableCell>
                        <TableCell>Business Address</TableCell>
                        <TableCell>Enigma Score</TableCell>
                        <TableCell>Account Status</TableCell>
                        <TableCell>Action</TableCell>
                        <TableCell>Request Document</TableCell>
                        <TableCell>Documents</TableCell>
                        <TableCell>Enigma JSON</TableCell>
                        <TableCell>DirectID JSON</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {sortedList.map((row, index) => {

                        const { address, suit, city, state, zipCode, country } = row
                        const addressArray: string[] = [address, suit, city, state, zipCode, country]
                        const modifiedAddress = addressArray.reduce((address: string[], item: string) => {
                          if (item) {
                            address.push(item)
                          }
                          return address
                        }, [])

                        const { id = '', isDocumentsRequested = false, documentsLastRequestedAt = '', accounts = '', bankDocuments = [] } = row?.bankRegistration

                        return (<TableRow
                          key={row.id}
                          className="textNormal"
                        // onClick={() => showRegistrationDetail(row.id)}
                        >
                          <TableCell component="th" scope="row">
                            {params.limit * (params.page - 1) + (index + 1)}
                          </TableCell>
                          <TableCell>{`${row.ownerFirstName} ${row.ownerLastName}`}</TableCell>
                          <TableCell>{row.ownerTitle}</TableCell>
                          <TableCell>{row['categoryDetails.name']}</TableCell>
                          <TableCell>{row.name}</TableCell>
                          <TableCell>{modifiedAddress.join(',')}</TableCell>
                          <TableCell>{row.enigmaScore}</TableCell>
                          <TableCell
                            style={{
                              color: ACCOUNT_STATUS_COLOR[row.status],
                              fontFamily: 'PlusJakartaSans-Bold',
                            }}
                          >
                            {BUSINESS_CATEGORIES[row.status]}
                          </TableCell>
                          <TableCell className="actionLink">
                            {row?.status === PENDING_ENGIMA_VERIFICATION && (
                              <>
                                <div className="btn_approve">
                                  <Button
                                    variant="outlined"
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      approveBusinessRegistration(row.id);
                                    }}
                                  >
                                    Approve
                                  </Button>
                                </div>
                                <div className="btn_reject">
                                  <Button
                                    variant="outlined"
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      rejectBusinessRegistration(row.id);
                                    }}
                                  >
                                    Reject
                                  </Button>
                                </div>
                              </>
                            )}
                            {row?.status === VERIFICATION_UNDER_WAY && (
                              <>
                                <div style={{ display: 'flex' }}>
                                  <Button
                                    onClick={(e) => {
                                      // e.stopPropagation();
                                      approveBankRegistration(row?.id, id);
                                    }}
                                  >
                                    Approve
                                  </Button>
                                  <Button
                                    onClick={() => {
                                      rejectBankRegistration(row?.id, id);
                                    }}
                                  >
                                    Reject
                                  </Button>
                                </div>
                                <Button
                                  onClick={() => {
                                    requestBankDocument(row?.id, id);
                                  }}
                                >
                                  Request Document
                                </Button>
                                <Button
                                  onClick={() => {
                                    contactSupport(row?.email, row?.name);
                                  }}
                                >
                                  Contact Support
                                </Button>
                              </>
                            )}
                            {(row?.status === PENDING_BANK_APPROVAL || row?.status === PENDING_STORE_FRONT_CREATION ) && (<Button
                              onClick={() => {
                                sendNudgeMail(row?.email, row?.name, row?.status);
                              }}
                            >
                              Nudge
                            </Button>)}
                          </TableCell>
                          <TableCell>
                            {isDocumentsRequested ? `Requested on ${formatDate(documentsLastRequestedAt)}` : `---`}
                          </TableCell>
                          <TableCell className='detailsBtn'>
                            <Button variant="outlined" onClick={() => showDocumentList(row?.id, bankDocuments)}>
                              Details
                            </Button>
                          </TableCell>
                          <TableCell className='detailsBtn'>
                            <Button
                              variant="outlined"
                              onClick={() => showRegistrationDetail(row.id)}
                            >
                              Details
                            </Button>

                          </TableCell>
                          <TableCell className='detailsBtn'>
                            <Button variant="outlined" onClick={() => showDirectIdJSON(row?.id, accounts)}>
                              Details
                            </Button>
                          </TableCell>
                        </TableRow>)
                      })}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Paper>
              <div className="paginationSec">
                <Pagination
                  count={registrationList?.pages}
                  shape="rounded"
                  defaultPage={params.page}
                  onChange={(event, value) => {
                    dispatch({ type: SET_PARAMETERS, body: { page: value } });
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <ConfirmationDialog openDialog={openDialog} setDialog={setDialog} action={action} />
    </>
  );
}
