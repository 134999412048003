import { AnyAction } from 'redux';
import { LOGOUT_SUCCESS } from '../types/login';
import { SET_PARAMETERS } from '../types/request';

export type ParametersState = {
  page: number;
  limit: number;
  order: string;
  sort: string;
  name: string;
};
const initialState: ParametersState = {
  page: 1,
  limit: 4,
  order: 'DESC',
  sort: 'updatedAt',
  name: '',
};
function parametersReducer(
  state: ParametersState = initialState,
  action: AnyAction,
): ParametersState {
  switch (action.type) {
    case SET_PARAMETERS:
      return { ...state, ...action.body };

    case LOGOUT_SUCCESS: {
      return initialState;
    }

    default:
      return state;
  }
}

export default parametersReducer;
