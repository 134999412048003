import { Middleware } from 'redux';
import { AppActions } from './types';
import { START_LOADING, STOP_LOADING } from './types/loader';
import { LOGOUT_SUCCESS } from './types/login';

export const CALL_API = 'CALL_API';
export const BASE_URL = '/api/v2';

const DEFAULT_HEADERS = {
  Accept: 'application/json',
  'Content-Type': 'application/json',
  'Cache-Control': 'no-cache, no-store, must-revalidate',
  Pragma: 'no-cache',
  Expires: 0,
};

class AuthError extends Error {}

interface CustomError {
  error: boolean;
  message: string;
  code: number;
}

export interface ResponseBody {
  type: AppActions;
  body: ApiResBody;
}

interface ApiResBody {
  data: any;
  count: number;
  error: string[];
  success: boolean;
}
export interface ResponseType {
  data: any;
  error: null | CustomError;
}

async function invokeAPI(
  endpoint: string,
  token: string = '',
  config: any,
  headerContent = {},
): Promise<ResponseType> {
  let headers = token
    ? { ...DEFAULT_HEADERS, Authorization: `Bearer ${token}` }
    : { ...DEFAULT_HEADERS };
  const updatedConfig = {
    ...config,
    headers: { ...headers, ...headerContent },
  };

  const response = await fetch(BASE_URL + endpoint, updatedConfig);
  const body = await response.json();
  if (response.status === 401) {
    const { code } = body;
    throw new AuthError(code);
  }
  if (response.status >= 400) {
    const { __globals = [], code, ...errors } = body;
    const [firstError] = [...Object.values(errors), ...__globals];
    return {
      data: null,
      error: {
        message: firstError || 'Something went wrong',
        code: response.status,
        error: true,
      },
    };
  }
  return { data: body, error: null };
}

export const middleWareDispatch: Middleware = (store) => (next) => async (action) => {
  if (typeof action[CALL_API] === 'undefined') {
    return next(action);
  }
  const {
    url,
    types,
    body,
    method,
    params,
    parameters = {},
    showLoader = false,
  } = action[CALL_API];
  const queryParams = new URLSearchParams();
  for (const param in params) {
    if (params[param]) {
      queryParams.set(param, params[param]);
    }
  }
  const [successType, errorType] = types;
  try {
    if (showLoader) {
      next({ type: START_LOADING });
    }
    const {
      authReducer: { token = '' },
    } = store.getState();
    const { data, error }: ResponseType = await invokeAPI(
      url + (params ? '?' + queryParams.toString() : ''),
      token,
      {
        method,
        body: JSON.stringify(body),
      },
    );

    if (data) {
      next({ type: successType, body: data, parameters });
    } else {
      next({ type: errorType, body: data, parameters });
    }
    return { body: data, parameters, error };
  } catch (error) {
    if (error instanceof AuthError) {
      next({ type: LOGOUT_SUCCESS });
    }
    throw error;
  } finally {
    if (showLoader) {
      next({ type: STOP_LOADING });
    }
  }
};
