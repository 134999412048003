interface CurrencyFormat {
  amount: string | number;
  country: string;
  currency: 'USD' | 'INR';
}

export function formatAmount({
  currency = 'USD',
  country = 'en-US',
  amount = 0,
}: Partial<CurrencyFormat>) {
  let formatTypes = {
    style: 'currency',
    currency,
  };
  return Number(amount).toLocaleString(country, formatTypes);
}



export function formatDate(date: any) {
  const d = new Date(date);
  const day = d.getDate();
  return (
      day + " " +
      d.toLocaleString("en-US", {
          month: "short",
          year: "numeric",
      })
  );
}
