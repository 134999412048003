import { connect } from 'react-redux';
import { Redirect, Route, RouteProps } from 'react-router';
import { AuthType } from '.';
import { RootStateType } from '../redux-store/reducers';

function ProtectedRoute({ token, component: Component, ...rest }: AuthType & RouteProps) {
  return (
    <Route
      {...rest}
      render={(props) =>
        !!token ? <Component {...props} /> : <Redirect to="/login"></Redirect>
      }
    />
  );
}
export default connect(({ authReducer }: RootStateType) => {
  return { token: authReducer.token };
}, {})(ProtectedRoute);
