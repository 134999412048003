import { SIDENAV_ITEMS } from '../../utils/constants';
import SideBar from '../forms/SideBar';
import {
  Button,
  Paper,
  Box,
  Table,
  TableCell,
  TableHead,
  TableRow,
  TableContainer,
  TableBody,
} from '@material-ui/core';
import { useEffect, useState } from 'react';
import Switch from '@material-ui/core/Switch';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { useRouteMatch, useHistory } from 'react-router-dom';
import React from 'react';
import { getEntities, updateEntityStatus } from '../../redux-store/actions/entity';
import { useDispatch, useSelector } from 'react-redux';
import { Entites } from '../../redux-store/reducers/entity';
import CreateEntity from './CreateEntity';
import { onLogout } from '../../redux-store/actions/login';
import Header from '../forms/headerBar';

function UserManagement(props: any) {
  const { url } = useRouteMatch();
  const history = useHistory();

  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const dispatch = useDispatch();
  const entityList = useSelector((state: { entites: Entites }) => state.entites);
  const loader = useSelector((state : {loader : {showLoader : number}}) => state.loader)

  const changeEntityStatus = async (entityId: string, status: boolean) => {
    await dispatch(updateEntityStatus(entityId, status));
  };

  useEffect(() => {
    async function fetch() {
      await dispatch(getEntities());
    }
    fetch();
  }, []);

  return (
    <div className="flex_column">
      <Header />
      <div className="flex_row minHeight bgThemeColor">
        <SideBar history={props.history} list={SIDENAV_ITEMS} />
        <div className="container">
          <div className="umHeader">
            <Button variant="contained" color="primary" onClick={handleClickOpen}>
              Add Entity
            </Button>
          </div>

          <Paper square className="umTableSec" elevation={0}>
            <TableContainer className="tableWidth">
              <Table aria-label="simple table">
                <TableHead>
                  <TableRow className="textBold">
                    <TableCell>S. No.</TableCell>
                    <TableCell>Type of Entity</TableCell>
                    <TableCell>Name</TableCell>
                    <TableCell>Commitment Amount</TableCell>
                    <TableCell>Activate/Deactivate</TableCell>
                    <TableCell></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {entityList.map((entity, index) => (
                    <TableRow className="textNormal">
                      <TableCell>{index + 1}</TableCell>
                      <TableCell className="textTransform">
                        {entity?.entityType}
                      </TableCell>
                      <TableCell>{entity?.name}</TableCell>
                      <TableCell>
                        {entity?.entityType === 'lender'
                          ? `USD ${entity?.commitmentAmount}`
                          : 'NA'}
                      </TableCell>
                      <TableCell>
                        <Box>
                          <FormControlLabel
                            className={
                              entity?.isActive ? 'switchEnable' : 'switchDisable'
                            }
                            control={
                              <Switch
                                size="small"
                                color="primary"
                                checked={entity?.isActive}
                                onChange={(e) =>
                                  changeEntityStatus(entity?.id, e.target.checked)
                                }
                              />
                            }
                            labelPlacement="start"
                            label=""
                          />
                        </Box>
                      </TableCell>
                      <TableCell align="right">
                        <Button
                          variant="outlined"
                          color="primary"
                          onClick={() =>
                            history.push(`/user-management/${entity?.id}/users`)
                          }
                        >
                          View Users
                        </Button>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
              {!loader.showLoader && (entityList?.length === 0) && <div
                    className='noUsersText'
                >
                    No Bank/Lender
                </div>}
            </TableContainer>
          </Paper>
          <CreateEntity open={open} handleClose={handleClose} />
        </div>
      </div>
    </div>
  );
}

export default UserManagement;
