export const GET_ENTITIES = 'GET_ENTITIES';
export const GET_ENTITIES_SUCCESS = 'GET_ENTITIES_SUCCESS';

export const CREATE_ENTITY = 'CREATE_ENTITY';
export const CREATE_ENTITY_SUCCESS = 'CREATE_ENTITY_SUCCESS';

export const UPDATE_ENTITY_STATUS = 'UPDATE_ENTITY_STATUS';
export const UPDATE_ENTITY_STATUS_SUCCESS = 'UPDATE_ENTITY_STATUS_SUCCESS';

export const GET_ENTITY_USERS = 'GET_ENTITY_USERS';
export const GET_ENTITY_USERS_SUCCESS = 'GET_ENTITY_USERS_SUCCESS';

export const ADD_ENTITY_USER = 'ADD_ENTITY_USER';
export const ADD_ENTITY_USER_SUCCESS = 'ADD_ENTITY_USER_SUCCESS';

export const UPDATE_ENTITY_USER_STATUS = 'UPDATE_ENTITY_USER_STATUS';
export const UPDATE_ENTITY_USER_STATUS_SUCCESS = 'UPDATE_ENTITY_USER_STATUS_SUCCESS';

interface EntityType {
  id: string;
  entityType: string;
  name: string;
  commitmentAmount: number;
  isActive: boolean;
  createdAt: string;
  updatedAt: string;
}

interface EntityPayloadType {
  entityType: string;
  name: string;
  commitmentAmount?: number;
}

export type Entity = EntityType;
export type EntityPayload = EntityPayloadType;

export interface User {
  id: string;
  entityId: string;
  userId: string;
  isActive: boolean;
  user: {
    firstName: string;
    lastName: string;
    email: string;
    phone: string;
    role: string;
    emailVerified: boolean;
    phoneVerified: boolean;
    passwordExists: boolean;
  };
  createdAt: string;
  updatedAt: string;
}

// export type User = UserType
// export type Users = Array<User>

interface EntityUserSchema {
  name: string;
  email: string;
}

export type EntityUser = EntityUserSchema;
