import { Paper } from '@material-ui/core';
import { useEffect, useState } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import {
  getConsumerLoansForMainLoan,
  getLoanDates,
  getLoans,
} from '../../redux-store/actions/home';
import moment from 'moment';
import CustomTable, { Row } from '../forms/Table';
import {
  StateProps,
  maturedTableHeaders,
  maturedTableValues,
  maturedConsumerValues,
  Headers,
  maturedTableHeadersForAdmin,
  maturedTableValuesForAdmin,
  maturedConsumerValuesForBank,
  MaturedLoan,
  ConsumerLoanAccount,
} from './types';
import { RootStateType } from '../../redux-store/reducers';
import Header from '../forms/headerBar';
import LenderBar from './lenderBar';
import SideBar from '../forms/SideBar';
import { SIDENAV_ITEMS } from '../../utils/constants';

const additionalHeaders: { [key: string]: Headers[] } = {
  'lender-user': [{ label: '28 days interest', type: 'percent' }],
  'bank-user': [{ label: '2 days interest', type: 'percent' }],
};

function LoanAccounts({ getLoans, getLoanDates, user, ...props }: StateProps) {
  const dispatch = useDispatch();
  const [data, setLoanData] = useState<MaturedLoan>({
    maturedLoans: [],
  });
  const [date, setDate] = useState<string>(moment(new Date()).format('l'));
  const [loading, setLoading] = useState<boolean>(false);

  const { entity } = useSelector((store: RootStateType) => store.authReducer);

  useEffect(() => {
    setLoading(true);
    async function fetchLoanInfo() {
      try {
        const { maturedLoans } = (await getLoans({ date })) || {};
        setLoanData({ maturedLoans });
        setLoading(false);
      } catch (err) {
        throw err;
      }
    }
    fetchLoanInfo();
  }, [getLoans, date]);

  const fetchConsumerLoansForMaturedLoan = async (loanId: string) => {
    const consumerLoans = (await dispatch(
      getConsumerLoansForMainLoan(loanId, 'matured'),
    )) as unknown as ConsumerLoanAccount[];

    if (!consumerLoans.length) {
      return null;
    }

    return consumerLoans.map((loan, index) => (
      <Row
        key={index}
        path={
          user.role === 'admin' ? maturedConsumerValues : maturedConsumerValuesForBank
        }
        headers={
          user.role === 'admin'
            ? [
                ...maturedTableHeadersForAdmin,
                ...(Object.keys(additionalHeaders).includes(user.role)
                  ? additionalHeaders[user.role]
                  : []),
              ]
            : maturedTableHeaders
        }
        row={loan}
        index={index}
      ></Row>
    ));
  };

  const {maturedLoans} = data;
  return (
    <div className="flex_column">
      <Header />
      <div className="flex_row minHeight bgThemeColor">
        <SideBar list={SIDENAV_ITEMS} />
        <div className="loanWrapper">
          {user.role === 'lender-user' && <LenderBar entity={entity} />}
          <div className="loan_container">
            <div className="headingText">MATURED LOAN PORTFOLIO</div>
            <Paper className="loanLogSec" square elevation={0}>
              {/* <div>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <KeyboardDatePicker
                    disableToolbar
                    autoOk
                    variant="inline"
                    format="MM/dd/yyyy"
                    margin="normal"
                    id="date-picker-inline"
                    label="Select Date"
                    value={date}
                    onChange={(date) => setDate(() => moment(date).format('l'))}
                    KeyboardButtonProps={{
                      'aria-label': 'change date',
                    }}
                  />
                </MuiPickersUtilsProvider>
              </div> */}
              {/* <div className="flex_column" style={{ alignItems: 'flex-end' }}>
          <Select
            placeholder="Date"
            onChange={({ target: { value } }: any) => setDate(value)}
            value={date}
          >
            {loanDates.map((date) => (
              <MenuItem key={date} value={moment(date).format('l')}>
                {moment(date).format('l')}
              </MenuItem>
            ))}
          </Select>
        </div> */}
              {!loading ? (
                <>
                  <CustomTable
                    headers={
                      user.role === 'admin'
                        ? [
                            ...maturedTableHeadersForAdmin,
                            ...(Object.keys(additionalHeaders).includes(user.role)
                              ? additionalHeaders[user.role]
                              : []),
                          ]
                        : maturedTableHeaders
                    }
                    data={maturedLoans}
                    path={
                      user.role === 'admin'
                        ? maturedTableValuesForAdmin
                        : maturedTableValues
                    }
                    fetchDataForRow={fetchConsumerLoansForMaturedLoan}
                    title="Matured Loan Portfolio"
                    enableGroups={true}
                  />
                </>
              ) : (
                <p>{`Loading...`}</p>
              )}
            </Paper>
          </div>
        </div>
      </div>
    </div>
  );
}

export default connect(({ authReducer: { user } }: RootStateType) => ({ user }), {
  getLoans,
  getLoanDates,
})(LoanAccounts);
