import { Box, Typography } from '@material-ui/core';
import { useDispatch } from 'react-redux';
import { onLogout } from '../../redux-store/actions/login';
import { SIDENAV_ITEMS } from '../../utils/constants';
import Header from '../forms/headerBar';
import SideBar from '../forms/SideBar';

export default function Setting(props: any) {
  const dispatch = useDispatch();
  return (
    <div className="flex_column">
      <Header />
      <div className="flex_row home bgThemeColor">
        <SideBar history={props.history} list={SIDENAV_ITEMS} />
        <div className="main-body">
          <Typography>Coming Soon...</Typography>
        </div>
      </div>
    </div>
  );
}
