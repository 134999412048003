import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormHelperText,
  Input,
  InputLabel,
} from '@material-ui/core';
import { useFormik } from 'formik';
import { useSnackbar } from 'notistack';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import * as yup from 'yup';
import { addEntityUser } from '../../redux-store/actions/entity';
import { User } from '../../redux-store/types/entity';

const validationSchema = yup.object({
  name: yup.string().trim().required('Name is required'),
  email: yup.string().email('Must be a valid email').required('Email is required'),
});

export default function CreateEntityUser({
  open,
  handleClose,
  entityId,
  addEntityUserToList,
}: {
  open: boolean;
  handleClose: () => void;
  entityId: string;
  addEntityUserToList: (user: User) => void;
}) {
  const dispatch = useDispatch();
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const formik = useFormik({
    initialValues: {
      name: '',
      email: '',
    },
    validationSchema: validationSchema,
    onSubmit: async (values, { resetForm }) => {
      try {
        handleClose();
        const entityUser = await dispatch(addEntityUser(entityId, values));
        addEntityUserToList(entityUser as unknown as User);
        // resetForm();

        enqueueSnackbar('User added successfully', {
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'center',
          },
          variant: 'success',
        });
      } catch (error) {
        enqueueSnackbar((error as Error).message, {
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'center',
          },
          variant: 'error',
        });
      } finally {
        resetForm();
      }
    },
  });

  const clickOutsideHandler = (event: any, reason: string) => {
    if (reason === 'backdropClick') {
      // formik.setFieldValue('name', '');
      // formik.setFieldValue('email', '');
      formik.resetForm()
    }
    handleClose();
  };

  const closeDialog = () => {
    // formik.setFieldValue('name', '');
    // formik.setFieldValue('email', '');
    formik.resetForm()
    handleClose();
  };

  return (
    <Dialog open={open} onClose={clickOutsideHandler} style={{ padding: 40 }}>
      <Box className="modalPopup">
        <Box onClick={closeDialog}>
          <img
            src="/icons/close_menu.svg"
            alt="close"
            style={{ float: 'right', cursor: 'pointer' }}
          />
        </Box>
        <DialogTitle>Add User</DialogTitle>
        <form onSubmit={formik.handleSubmit}>
          <DialogContent>
            <FormControl
              variant="standard"
              fullWidth
              margin="dense"
              error={formik.touched.name && Boolean(formik.errors.name)}
            >
              <InputLabel htmlFor="component-simple">Name</InputLabel>
              <Input
                name="name"
                value={formik.values.name}
                onChange={formik.handleChange}
              />
              <FormHelperText>{formik.touched.name && formik.errors.name}</FormHelperText>
            </FormControl>
            <FormControl
              variant="standard"
              fullWidth
              margin="dense"
              error={formik?.touched?.email && Boolean(formik?.errors?.email)}
            >
              <InputLabel htmlFor="component-simple">Email Id</InputLabel>
              <Input
                name="email"
                value={formik.values.email}
                onChange={formik.handleChange}
              />
              <FormHelperText>
                {formik?.touched?.email && formik?.errors?.email}
              </FormHelperText>
            </FormControl>
          </DialogContent>
          <DialogActions className="margin_bottom_30 margin_top_10">
            <Button
              variant="contained"
              color="primary"
              type="submit"
              disabled={formik.isSubmitting}
            >
              Save
            </Button>
          </DialogActions>
        </form>
      </Box>
    </Dialog>
  );
}
