import React, { useEffect, useState } from 'react';
import { connect, useSelector } from 'react-redux';
import {
  getLoanDates,
  getLoans,
  getLenderAccountBalance,
} from '../../redux-store/actions/home';
import { StateProps } from './types';
import { RootStateType } from '../../redux-store/reducers';
import Header from '../forms/headerBar';
import SideBar from '../forms/SideBar';
import { SIDENAV_ITEMS } from '../../utils/constants';
import LenderBar from './lenderBar';
import LenderConfirmedStatements from './lenderConfirmedStatements';
import { Link } from 'react-router-dom';
import { formatAmount } from '../../utils/commonFunctions';

function LoanAccounts({
  getLoans,
  getLoanDates,
  getLenderAccountBalance,
  user,
  ...props
}: StateProps) {
  const [toalAmount, setTotal] = useState<string>('');

  const { entity } = useSelector((store: RootStateType) => store.authReducer);
  const { role } = user;

  useEffect(() => {
    async function fetchTotalBalance() {
      try {
        const res = role === 'lender-user' ? await getLenderAccountBalance() : {};
        setTotal(res?.accountBalance);
      } catch (err) {
        throw err;
      }
    }
    fetchTotalBalance();
  }, [role, getLenderAccountBalance]);

  return (
    <div className="flex_column">
      <Header />
      <div className="flex_row minHeight bgThemeColor">
        <SideBar list={SIDENAV_ITEMS} />
        <div className="loanWrapper">
          {user.role === 'lender-user' && <LenderBar entity={entity} />}
          <div className="loan_container">
            <div className="cashAccTextContainer">
              <div className="headingText">Statement</div>
              <div>
                <Link
                  to={{
                    pathname: '/cash-account/pending-statements',
                  }}
                  className="pendingStatementLink link_text"
                >
                  Pending Statement
                </Link>
                {role === 'lender-user' && (
                  <div className="totalAccountBalance">
                    {`TOTAL :  ${formatAmount({ amount: toalAmount || 0 })}`}
                  </div>
                )}
              </div>
            </div>

            <LenderConfirmedStatements />
          </div>
        </div>
      </div>
    </div>
  );
}

export default connect(({ authReducer: { user } }: RootStateType) => ({ user }), {
  getLoans,
  getLoanDates,
  getLenderAccountBalance,
})(LoanAccounts);
