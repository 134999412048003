import { Box, Paper, Typography } from '@material-ui/core';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { getRegistrationDetail } from '../../redux-store/actions/request';
import { RegistrationsState } from '../../redux-store/reducers/registration';
import { Registration } from '../../redux-store/types/request';
import { SIDENAV_ITEMS } from '../../utils/constants';
import { useHistory } from 'react-router';
import SideBar from '../forms/SideBar';
import { onLogout } from '../../redux-store/actions/login';
import Header from '../forms/headerBar';

export default function MerchantDetail(props: any) {
  const history = useHistory();
  const { registrationId } = useParams<{ registrationId: string }>();
  // const registrationList = useSelector((state: { registrations: RegistrationsState }) => state.registrations);
  // const registrationFromRedux = registrationList.find(registration => registration.id === registrationId)
  const [registration, setRegistration] = useState({} as Registration);
  const dispatch = useDispatch();
  useEffect(() => {
    async function fetch(registrationId: string) {
      const registrationFromAPI = await dispatch(getRegistrationDetail(registrationId));
      setRegistration(registrationFromAPI as unknown as Registration);
    }
    //  if(!registration){
    fetch(registrationId || '');
    //  }
  }, [registrationId, dispatch]);

  return (
    <div className="flex_column">
      <Header />
      <div className="flex_row home bgThemeColor">
        <SideBar history={props.history} list={SIDENAV_ITEMS} />
        <div className="container">
          <div className="usersHeader">
            <div className="firstSec">
              <img
                src="/icons/back_icon.svg"
                alt="Go back"
                className="backImg"
                onClick={() => history.goBack()}
              />
              <Typography style={{ textTransform: 'none' }}>Enigma</Typography>
            </div>
          </div>
          <Paper square elevation={0} className="enigmaCard">
            <div className="enigmaSec">
              <pre>{JSON.stringify(registration?.enigmaResponse, null, 4)}</pre>
            </div>
          </Paper>
        </div>
      </div>
    </div>
  );
}
