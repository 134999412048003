import { combineReducers } from 'redux';
import entityReducer from './entity';
import authReducer from './login';
import registrationsReducer from './registration';
import loaderReducer from './loader';
import parametersReducer from './parameters';

export interface RootStateType {
  authReducer: any;
  router: any;
}

export const rootReducer = (history: any) =>
  combineReducers({
    router: history,
    authReducer,
    registrations: registrationsReducer,
    entites: entityReducer,
    loader: loaderReducer,
    parameters: parametersReducer,
  });

export type RootState = ReturnType<typeof rootReducer>;
