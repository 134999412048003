import { Redirect, Route, Switch } from 'react-router';
import ActiveLoan from './ActiveLoan';
import MaturedLoan from './MaturedLoan';
// import CashAccount from './CashAccount';
import CashIndex from './cashIndex';

interface RouteProps {
  path: string;
  component: any;
}
export function RouterOutLet({
  Routes,
  exactPath,
}: {
  Routes: RouteProps[];
  exactPath: any;
}) {
  return (
    <Switch>
      {Routes.map((route) => (
        <Route key={route.path} path={route.path} component={route.component} />
      ))}
      <Route
        path={`${exactPath.path}`}
        exact
        render={(props) => <Redirect to={exactPath.to} />}
      />
    </Switch>
  );
}

export default function DashboardFolder({ path }: { path: string }) {
  const Routes = [
    { path: `${path}/active-loan`, component: ActiveLoan },
    { path: `${path}/matured-loan`, component: MaturedLoan },
    { path: `${path}/cash-account`, component: CashIndex },
  ];
  return <RouterOutLet Routes={Routes} exactPath={{ path, to: `${path}/active-loan` }} />;
}
