import { Typography } from '@material-ui/core';
import { useEffect, useState } from 'react';
import { COLORS } from '../constants';

const spanTxt: any = {
  color: COLORS.juma_theme_clr,
  fontSize: 16,
  fontWeight: 600,
  marginLeft: 6,
};

export function Countdown({
  targetTime,
  setTimerExpired,
}: {
  targetTime: number;
  setTimerExpired: (timeExpired: boolean) => void;
}) {
  const [counter, setCounter] = useState(0);
  useEffect(() => {
    const intervalId = setInterval(tick, 1000);
    function tick() {
      if (targetTime > Date.now()) {
        setCounter(Math.round((targetTime - Date.now()) / 1000));
      } else {
        setCounter(0);
        clearInterval(intervalId);
        setTimerExpired(true);
      }
    }
    tick();
    return () => {
      clearInterval(intervalId);
    };
  }, [targetTime, setTimerExpired]);
  if (counter === 0) {
    return null;
  }
  let min = Math.floor(counter / 60);
  let sec = counter % 60;
  return (
    <Typography style={spanTxt}>{`${min < 10 ? `0${min}` : `${min}`} : ${sec < 10 ? `0${sec}` : `${sec}`}`}</Typography>
  );
}
